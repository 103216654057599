import React from 'react';
import styles from './InfoSection.module.scss';
import clsx from 'clsx';

export const InfoSection = ({
  iconComponent,
  heading,
  subheading
}) => {
  return (
    <section className={styles.wrapper} >
      {iconComponent}
      <div className={styles.content} >
        <p className={clsx(styles.text, styles.heading)}>{heading}</p>
        {subheading && (
          <p className={clsx(styles.text, styles.subheading)}>{subheading}</p>
        )}
      </div>
    </section>
  )
}
