import { format } from "date-fns";

export const groupedBookingsByDate = (data) => {
  if (!data) return;
  const groupByDate = data.reduce((group, booking) => {
    const { date } = booking;
    const formatedDate = format(new Date(date), "MMMM dd,yyyy");
    group[formatedDate] = group[formatedDate] ?? [];
    group[formatedDate].push(booking);
    return group;
  }, {});
  return Object.entries(groupByDate).map((values) => {
    return [
      ...values,
      values[1].reduce(
        (prevCost, { payment_breakdown }) => prevCost + payment_breakdown.cost,
        0
      ),
    ];
  });
};

const BookingGroupStatus = Object.freeze({
  UNKNOWN: "unknown",
  AVOID_ONLY: "avoidOnly",
  THROUGH_ONLY: "throughOnly",
  MIXED: "mixed",
});

function getStatusByBookingsList(bookings) {
  if (!Array.isArray(bookings) || bookings.length === 0) {
    return BookingGroupStatus.UNKNOWN;
  }

  if (bookings.every((booking) => booking.is_driverpay_booking === false)) {
    return BookingGroupStatus.AVOID_ONLY;
  }

  if (bookings.every((booking) => booking.is_driverpay_booking === true)) {
    return BookingGroupStatus.THROUGH_ONLY;
  }

  return BookingGroupStatus.MIXED;
}

function getTodayLabelByTodayAndYesterdayStatuses(
  yesterdayStatus,
  todayStatus
) {
  if (
    yesterdayStatus === BookingGroupStatus.UNKNOWN ||
    todayStatus === BookingGroupStatus.UNKNOWN
  ) {
    return "unknown";
  }

  if (
    yesterdayStatus === BookingGroupStatus.AVOID_ONLY &&
    (todayStatus === BookingGroupStatus.MIXED ||
      todayStatus === BookingGroupStatus.THROUGH_ONLY)
  ) {
    return "started";
  }

  if (
    (yesterdayStatus === BookingGroupStatus.MIXED ||
      yesterdayStatus === BookingGroupStatus.THROUGH_ONLY) &&
    todayStatus === BookingGroupStatus.AVOID_ONLY
  ) {
    return "stopped";
  }

  return "unknown";
}

export function getBookingGroups(data) {
  const grouped = groupedBookingsByDate(data) ?? [];
  return grouped.map((group, index) => {
    const bookingsFromToday = group[1];
    const bookingsFromYesterday = grouped[index + 1]?.[1];

    const todayStatus = getStatusByBookingsList(bookingsFromToday);
    const yesterdayStatus = getStatusByBookingsList(bookingsFromYesterday);
    const todayLabel = getTodayLabelByTodayAndYesterdayStatuses(
      yesterdayStatus,
      todayStatus
    );

    return {
      title: group[0],
      subtitle: group[2],
      items: bookingsFromToday,
      todayLabel,
    };
  });
}
