import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "components/Form";
import { Field } from "components/Field";
import { FormPasswordInput } from "components/FormPasswordInput/FormPasswordInput";
import { Button } from "components/Button";

export function CreateNewPasswordForm({ onSubmit }) {
  const { t } = useTranslation();

  const renderForm = useCallback(
    ({ values, errors, onChange }) => {
      return (
        <>
          <Field
            label={t("field.password")}
            htmlFor="password"
            error={errors.password}
          >
            <FormPasswordInput
              id="password"
              name="password"
              value={values.password}
              onChange={onChange}
            />
          </Field>

          <Field
            label={t("field.passwordConfirm")}
            htmlFor="passwordConfirm"
            error={errors.passwordConfirm}
          >
            <FormPasswordInput
              id="passwordConfirm"
              name="passwordConfirm"
              value={values.passwordConfirm}
              onChange={onChange}
            />
          </Field>

          <Field>
            <Button block type="submit" variant="primary" shape="rounded">
              {t("action.continue")}
            </Button>
          </Field>
        </>
      );
    },
    [t]
  );

  const validationRules = useMemo(() => {
    return [
      {
        field: "password",
        isValid: (value) => typeof value === "string" && value.length > 0,
        error: t("error.passwordRequired"),
      },
      {
        field: "password",
        isValid: (value) => typeof value === "string" && value.length > 8,
        error: t("error.passwordMustBeLonger"),
      },
      {
        field: "passwordConfirm",
        isValid: (value) => typeof value === "string" && value.length > 0,
        error: t("error.passwordConfirmationRequired"),
      },
      {
        field: "passwordConfirm",
        isValid: (value, form) => value === form.password,
        error: t("error.passwordsNotMatch"),
      },
    ];
  }, [t]);

  return (
    <Form
      validationRules={validationRules}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {renderForm}
    </Form>
  );
}

const initialValues = {
  password: "",
  passwordConfirm: "",
};
