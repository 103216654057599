import React from 'react';
import styles from './NotificationTicket.module.scss';
import { ReactComponent as SuccessIcon } from 'assets/icons/notification_success.svg';
import { format } from 'date-fns';

export const NotificationTicket = ({
  title,
  type,
  created_at,
  message
}) => {
  return (
    <div className={styles.container} >
      <SuccessIcon />
      <div className={styles.contentWrapper} >
        <p className={styles.notificationHeader} >{title}</p>
        <p className={styles.notificationContent} >
          {message}
        </p>
        <p className={styles.notificationDate} >
          {format(new Date(created_at), "MMMM dd, yyyy, hh:mm aaaaa'm'")}
        </p>
      </div>
    </div>
  )
}
