import { isBefore } from "date-fns";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { storeOnVapor } from "helpers/storeOnVapor";
import { useFeatureFlagsFromSearchParams } from "hooks/useFeatureFlagsFromSearchParams";

import { Field } from "components/Field";

import { Title } from "components/Title";
import { Description } from "components/Description";
import { Input } from "components/Input";

import { Select } from "components/Select";
import { DatePicker } from "components/DatePicker";
import { Button } from "components/Button";
import { Signature } from "components/Signature";
import { Spacer } from "components/Spacer";

import { ValidationWrapper } from "../ValidationWrapper";
import { FieldType } from "../FieldType";
import { MultipleFilePicker } from "../MultipleFilePicker";
import {
  decodeExtraInfo,
  decodeValue,
  encodeExtraInfo,
  encodeValue,
} from "../converters";

import { Badge, BadgeList } from "./Badge";
import { useDocumentItemExternalLink } from "../useDocumentItemExternalLink";

export function DocumentForm({
  isEditPage,
  document,
  updateBefore,
  onSave,
  buttons = [],
  documentType,
}) {
  const { t } = useTranslation();
  const { isSignatureEnabled } = useFeatureFlagsFromSearchParams();

  const { flag, handleClickExternalLink } = useDocumentItemExternalLink();
  const [signatureDataURL, setSignatureDataURL] = useState("");

  const [extraInfo, setExtraInfo] = useState(() => {
    return decodeExtraInfo({}, documentType);
  });

  const [expireAt, setExpireAt] = useState(() => {
    return decodeValue(document?.expire_at, FieldType.DATETIME);
  });

  const [files, setFiles] = useState([]);

  useEffect(() => {
    setExtraInfo(decodeExtraInfo(document?.extra_info, documentType));
    setFiles(document?.files ?? []);
  }, [document, documentType]);

  const [loadingFiles, setLoadingFiles] = useState([]);
  function onFileUpload(fileData) {
    const fileDataArray = Array.isArray(fileData) ? fileData : [fileData];

    fileDataArray.forEach((file) => {
      setLoadingFiles((currArray) => [...currArray, file.lastModified]);
      storeOnVapor(file).then((apiFile) => {
        setLoadingFiles((currArray) =>
          currArray.filter((key) => key !== file.lastModified)
        );
        setFiles((previousFiles) => [
          ...previousFiles,
          Object.assign(file, { ...apiFile }),
        ]);
      });
    });

    setErrors((prevState) => ({
      ...prevState,
      files: undefined,
    }));
  }

  function onFileRemove(newFiles) {
    setFiles(newFiles);

    setErrors((prevState) => ({
      ...prevState,
      files: undefined,
    }));
  }

  const onResetSignatureDataURL = useCallback(() => {
    setSignatureDataURL("");
  }, [setSignatureDataURL]);

  const [errors, setErrors] = useState({});

  const onChangeSignatureDataURL = useCallback(
    (dataURL) => {
      setErrors((prevState) => ({
        ...prevState,
        signature: undefined,
      }));
      setSignatureDataURL(dataURL);
    },
    [setSignatureDataURL, setErrors]
  );

  function onDocumentSave() {
    const errors = getValidationErrors({
      extraInfo,
      expireAt,
      files,
      documentType,
      signatureDataURL: isSignatureEnabled ? signatureDataURL : undefined,
      t,
    });
    setErrors(errors);

    const hasErrors =
      Object.entries(errors).filter(([_, value]) => typeof value === "string")
        .length > 0;

    if (hasErrors) {
      return;
    }

    onSave({
      files,
      extraInfo: encodeExtraInfo(extraInfo, documentType),
      expireAt: Boolean(expireAt)
        ? encodeValue(expireAt, FieldType.DATETIME)
        : undefined,
    });
  }

  return (
    <ValidationWrapper>
      {updateBefore !== undefined ? (
        <>
          <BadgeList>
            <Badge>
              {t("componentDocumentBadge.updateBeforeDate", {
                date: format(new Date(updateBefore), "MMMM dd, yyyy"),
              })}
            </Badge>
          </BadgeList>
          <Spacer />
        </>
      ) : undefined}
      <Title>{documentType.name}</Title>
      <Description>
        {flag ? (
          <div
            dangerouslySetInnerHTML={{ __html: documentType.description }}
            onClick={handleClickExternalLink}
          ></div>
        ) : (
          <p>{documentType.description}</p>
        )}
      </Description>
      <div>
        {documentType.extra_info.map((field) => (
          <Field
            key={field.name}
            label={field.label ?? field.name}
            htmlFor={field.name}
            error={errors[field.name]}
          >
            {field.type === FieldType.TEXT ? (
              <Input
                id={field.name}
                value={extraInfo[field.name]}
                disabled={!isEditPage}
                onChange={(event) => {
                  setExtraInfo((prevState) => ({
                    ...prevState,
                    [field.name]: event.target.value,
                  }));

                  setErrors((prevState) => ({
                    ...prevState,
                    [field.name]: undefined,
                  }));
                }}
              />
            ) : null}

            {field.type === FieldType.SELECT ? (
              <Select
                id={field.name}
                options={[
                  {
                    value: "-",
                    children: t("field.selectOption"),
                    disabled: true,
                  },
                ].concat(field.payload.options)}
                value={
                  [
                    {
                      value: "-",
                      children: t("field.selectOption"),
                      disabled: true,
                    },
                  ]
                    .concat(field.payload.options)
                    .find((option) => option.value === extraInfo.badge_type)
                    ?.value
                }
                disabled={!isEditPage}
                onChange={(event) => {
                  setExtraInfo((prevState) => ({
                    ...prevState,
                    [field.name]: event.target.value,
                  }));

                  setErrors((prevState) => ({
                    ...prevState,
                    [field.name]: undefined,
                  }));
                }}
              />
            ) : null}

            {field.type === FieldType.DATETIME ? (
              <DatePicker
                id={field.name}
                placeholder=""
                getPopupContainer={(trigger) => trigger.parentNode}
                value={extraInfo[field.name]}
                disabledDate={(current) => {
                  return isBefore(new Date(current), new Date());
                }}
                disabled={!isEditPage}
                onChange={(date) => {
                  setExtraInfo((prevState) => ({
                    ...prevState,
                    [field.name]: date,
                  }));

                  setErrors((prevState) => ({
                    ...prevState,
                    [field.name]: undefined,
                  }));
                }}
              />
            ) : null}
          </Field>
        ))}

        {documentType.expiry_date_required ? (
          <Field
            label={t("field.expiryDate")}
            htmlFor="expire_at"
            error={errors.expire_at}
          >
            <DatePicker
              id="expire_at"
              placeholder=""
              getPopupContainer={(trigger) => trigger.parentNode}
              value={expireAt}
              disabledDate={(current) => {
                return isBefore(new Date(current), new Date());
              }}
              disabled={!isEditPage}
              onChange={(date) => {
                setExpireAt(date);

                setErrors((prevState) => ({
                  ...prevState,
                  expire_at: undefined,
                }));
              }}
            />
          </Field>
        ) : null}

        {documentType.upload_required ? (
          <Field
            label={
              isEditPage
                ? documentType.file_upload_label ?? t("field.documentsList")
                : t("field.documentsList")
            }
            htmlFor="files"
            error={errors.files}
          >
            <>
              {isEditPage && documentType?.helper_text?.length > 0 ? (
                <p>{documentType.helper_text}</p>
              ) : null}
              <MultipleFilePicker
                id="files"
                multiple
                providedFiles={files}
                loadingArray={loadingFiles}
                viewOnly={!isEditPage}
                maxFiles={Infinity}
                onChange={onFileUpload}
                onRemove={onFileRemove}
              />
            </>
          </Field>
        ) : null}

        {isEditPage && isSignatureEnabled ? (
          <Field
            label={"Signature"}
            error={errors.signature}
            description={
              signatureDataURL !== ""
                ? t("success.signatureAccepted")
                : undefined
            }
          >
            <Signature
              value={signatureDataURL}
              onReset={onResetSignatureDataURL}
              onChange={onChangeSignatureDataURL}
            />
          </Field>
        ) : null}

        {buttons.map((buttonProps, index) => {
          const { onClick, ...otherProps } = buttonProps;

          return (
            <Fragment key={otherProps.children}>
              <Field dense={index === buttons.length - 1}>
                {onClick === "onSave" ? (
                  <Button {...otherProps} onClick={onDocumentSave} />
                ) : (
                  <Button {...otherProps} onClick={onClick} />
                )}
              </Field>
            </Fragment>
          );
        })}
      </div>
    </ValidationWrapper>
  );
}

function getValidationErrors({
  extraInfo,
  expireAt,
  files,
  documentType,
  signatureDataURL,
  t,
}) {
  const errors = documentType.extra_info.reduce((acc, field) => {
    const error =
      extraInfo?.[field.name] === undefined ||
      extraInfo?.[field.name] === "" ||
      (field.type === FieldType.SELECT && extraInfo?.[field.name] === "-")
        ? t("error.fieldShouldNotBeEmpty")
        : undefined;

    if (error === undefined) {
      return acc;
    }

    return {
      ...acc,
      [field.name]: error,
    };
  }, {});

  if (
    documentType.expiry_date_required &&
    (expireAt === null || expireAt === undefined)
  ) {
    errors.expire_at = t("error.fieldShouldNotBeEmpty");
  }

  const minFiles = documentType?.minimum_files_to_upload ?? 1;

  if (documentType.upload_required && files.length < minFiles) {
    errors.files = t("error.fileMissing", { count: minFiles });
  }

  const maxFiles = documentType?.maximum_files_to_upload ?? Infinity;

  if (documentType.upload_required && files.length > maxFiles) {
    errors.files = t("error.fileExtra", { count: maxFiles });
  }

  if (
    documentType.upload_required &&
    files.some((file) => isFileFromBackend(file))
  ) {
    errors.files = t("error.reattachFilesAndTryAgain");
  }

  if (typeof signatureDataURL === "string" && signatureDataURL.length === 0) {
    errors.signature = t("error.signatureEmpty");
  }

  return errors;
}

function isFileFromBackend(file) {
  // local files are objects
  return typeof file === "string";
}
