import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import { Title } from "components/Title";
import { Button } from "components/Button";

import { StripeBankAccountForm } from "./StripeBankAccountForm";
import styles from "./EnterPasswordToContinueModal.module.scss";

export function BankDetailsModal({
  visible,
  loading,
  bankDetails,
  onCancel,
  onSubmit,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      centered
      closable={false}
      footer={null}
      onCancel={onCancel}
      className={styles.enterPasswordToContinueModal}
    >
      <Title>{t("pageProfile.updateBankDetailsTitle")}</Title>
      <StripeBankAccountForm
        initialValues={{
          country: bankDetails.country,
          currency: bankDetails.currency?.toUpperCase(),
          accountNumber: "",
          routingNumber: bankDetails.routing_number,
        }}
        placeholders={{
          accountNumber: bankDetails.last4?.padStart(16, "*"),
        }}
        onSubmit={onSubmit}
        renderFooter={() => {
          return (
            <div
              style={{ display: "flex", justifyContent: "flex-end", gap: 20 }}
            >
              <Button shape="rounded" onClick={onCancel}>
                {t("action.cancel")}
              </Button>
              <Button
                type="submit"
                variant="primary"
                shape="rounded"
                loading={loading}
                disabled={loading}
              >
                {t("action.save")}
              </Button>
            </div>
          );
        }}
      />
    </Modal>
  );
}
