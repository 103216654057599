import { useAuth } from "./useAuth";
import { UserRoles } from "api";

export function useRoles() {
  const { user } = useAuth();

  const isDriver = user?.roles.includes(UserRoles.DRIVER);
  const isFleetAdmin = user?.roles.includes(UserRoles.FLEET_ADMIN);
  const isFleetAdminReadonly = user?.roles.includes(
    UserRoles.FLEET_ADMIN_READONLY
  );
  const isSuperAdmin = user?.roles.includes(UserRoles.SUPER_ADMIN);
  const isFleet = isFleetAdmin || isFleetAdminReadonly || isSuperAdmin;
  const isSupport = user?.roles.includes(UserRoles.SUPPORT);

  return {
    isDriver,
    isFleetAdmin,
    isFleetAdminReadonly,
    isSuperAdmin,
    user,
    roles: user?.roles,
    isFleet,
    isDriverAndFleet: isDriver && isFleet,
    isAdmin: isFleetAdmin || isFleetAdminReadonly || isSuperAdmin,
    isSupport,
  };
}

export function allowAllAdmins(roles) {
  return roles.isFleetAdmin || roles.isFleetAdminReadonly || roles.isSuperAdmin;
}

export function allowSuperAdmins(roles) {
  return roles.isSuperAdmin;
}

export function allowDrivers(roles) {
  return roles.isDriver;
}

export function allowDriversWhoAdmins(roles) {
  return allowAllAdmins(roles) && allowDrivers(roles);
}

export default useRoles;
