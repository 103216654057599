import React, { useEffect } from "react";
import "./StripeConfirmationModal.scss";
import { ConfirmationModal } from "components/ConfirmationModal";
import { Container } from "components/Container";
import { useIsMobile } from "hooks/useMobile";
import { StripeKyc } from "./StripeKYC";
import { SelectAnotherCompany } from "./SelectAnotherCompany";

export const StripeConfirmationModal = ({
  visible,
  driverProfileId,
  driverProfile,
  allFleets = [],
  onFleetChange,
  selectedFleet,
}) => {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile && visible) {
      document?.body.classList.add('overflow-hidden');
    } else {
      document?.body.classList.remove('overflow-hidden');
    };

    return () => {
      document?.body.classList.remove('overflow-hidden');
    }
  }, [isMobile, visible]);

  if (isMobile && visible) {
    return (
      <div className='stripeModalMobile stripe-confirm-modal'>
        <Container>
          <StripeKyc
            driverProfileId={driverProfileId}
            driverProfile={driverProfile}
            selectedFleetId={selectedFleet?.id}
            selectedFleetSlug={selectedFleet?.url_slug}
          >
            <SelectAnotherCompany
              allFleets={allFleets}
              onFleetChange={onFleetChange}
              selectedFleet={selectedFleet}
            />
          </StripeKyc>
        </Container>
      </div>
    )
  }

  return (
    <ConfirmationModal
      visible={visible}
      className="stripe-confirm-modal"
      footer={null}
      closable={false}
    >
      <Container>
        <StripeKyc
          driverProfileId={driverProfileId}
          driverProfile={driverProfile}
          selectedFleetId={selectedFleet?.id}
          selectedFleetSlug={selectedFleet?.url_slug}
        >
          <SelectAnotherCompany
            allFleets={allFleets}
            onFleetChange={onFleetChange}
            selectedFleet={selectedFleet}
          />
        </StripeKyc>
      </Container>
    </ConfirmationModal >
  );
};
