import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { message } from "antd";
import { useTranslation, Trans } from "react-i18next";

import { api } from "api";
import { routes } from "routes";

import { Spacer } from "components/Spacer";
import { Button } from "components/Button";
import { Title } from "components/Title";
import { Description } from "components/Description";
import { Small } from "components/Small";

export const OnboardingCheckEmailContent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { email: onboardingEmail } = useSelector(
    (state) => state.onboardingSlice
  );
  const { fleetSlug } = useParams();
  const { data } = api.endpoints.getMarketingPageByFleetSlug.useQuery({
    fleetSlug,
  });
  const fleetId = data?.data?.fleet_id ?? null;

  const [
    verifyUserEmail,
    {
      isLoading: isResendLoading,
      isSuccess: isResendSuccess,
      isError: isResendError,
    },
  ] = api.endpoints.verifyUserEmail.useMutation();

  useEffect(() => {
    if (isResendLoading) {
      message.loading({
        content: t("processing.resendingEmail"),
        duration: 0,
        key: "resend_loading",
      });
    } else {
      message.destroy("resend_loading");
    }
  }, [isResendLoading, t]);

  useEffect(() => {
    if (isResendSuccess) {
      message.success({ content: t("success.emailSent") });
    }

    if (isResendError) {
      message.error({ content: t("error.somethingWentWrong") });
    }
  }, [isResendSuccess, isResendError, t]);

  function onResendEmailClick() {
    verifyUserEmail({
      fleet_id: fleetId,
    });
  }

  const [
    getMe,
    {
      isLoading: isGetMeLoading,
      isSuccess: isGetMeSuccess,
      isError: isGetMeError,
    },
  ] = api.endpoints.getMe.useLazyQuery();

  useEffect(() => {
    if (isGetMeLoading) {
      message.loading({
        content: t("processing.verifying"),
        duration: 0,
        key: "resend_loading",
      });
    } else {
      message.destroy("resend_loading");
    }
  }, [isGetMeLoading, t]);

  useEffect(() => {
    if (isGetMeSuccess) {
      message.success({ content: t("success.verified") });
      navigate(routes.onboardingCreateDriverProfilePage.getURL(fleetSlug));
    }

    if (isGetMeError) {
      message.error({ content: t("error.resendVerificationEmail") });
    }
  }, [isGetMeSuccess, isGetMeError, navigate, fleetSlug, t]);

  function onContinueClick() {
    getMe();
  }

  return (
    <>
      <Title>{t("pageCheckVerificationEmail.thankYou")}</Title>
      <Description>
        <Trans
          i18nKey="pageCheckVerificationEmail.checkEmailClickVerify"
          values={{
            email: onboardingEmail ?? "",
          }}
          components={{
            email: <b />,
            emailButton: <b />,
          }}
        />
      </Description>
      <Spacer />
      <Description>
        {t("pageCheckVerificationEmail.checkSpamOrResend")}
      </Description>
      <Spacer />
      <Button
        variant="primary"
        block
        shape="round"
        fontWeight="bold"
        onClick={onResendEmailClick}
      >
        {t("action.resendVerificationEmail")}
      </Button>
      <Spacer size="small" />
      <Small>
        <div style={{ textAlign: "center" }}>
          <Trans
            i18nKey="pageCheckVerificationEmail.verifiedFromOtherDevice"
            components={{
              cta: (
                <Button
                  hideSpinner
                  variant="link"
                  fontWeight="bold"
                  fontSize="sm"
                  onClick={onContinueClick}
                />
              ),
            }}
          />
        </div>
      </Small>
    </>
  );
};
