export function getDefaultFleetIdByDriverProfiles(driverProfiles) {
  if (driverProfiles.length === 0) {
    return null;
  }

  const firstCompletelyActiveProfile = driverProfiles.find(
    isProfileCompletelyActive
  );
  if (firstCompletelyActiveProfile !== undefined) {
    return firstCompletelyActiveProfile.fleet.id;
  }

  const firstPartiallyActiveProfile = driverProfiles.find(
    isProfilePartiallyActive
  );
  if (firstPartiallyActiveProfile !== undefined) {
    return firstPartiallyActiveProfile.fleet.id;
  }

  return driverProfiles[0].fleet.id;
}

export function isProfileCompletelyActive(profile) {
  return profile?.stripe_details_submitted && profile?.stripe_payouts_enabled;
}

export function isProfilePartiallyActive(profile) {
  return profile?.stripe_details_submitted || profile?.stripe_payouts_enabled;
}
