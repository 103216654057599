import { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { message } from "antd";

import { routes } from "routes";
import { api } from "api";

import { Form } from "components/Form";
import { Field } from "components/Field";
import { emailRegex, Input } from "components/Input";
import { FormPasswordInput } from "components/FormPasswordInput/FormPasswordInput";
import { Button } from "components/Button";

export function SignInForm() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [searchParams] = useSearchParams();
  const joinFleetSlug = searchParams.get("join-fleet");

  const [logIn] = api.endpoints.login.useMutation();

  const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);

  const onSubmit = useCallback(
    ({ email, password }) => {
      setIsPasswordIncorrect(false);

      message.loading({
        content: t("processing.signingIn"),
        key: "sign-in",
      });

      logIn({
        email,
        password,
      })
        .unwrap()
        .then((response) => {
          const { meta } = response;
          localStorage.setItem("auth_token", JSON.stringify(meta.token));

          navigate(routes.index.getURL(joinFleetSlug), {
            replace: true,
            state,
          });

          message.success({
            content: t("success.signedIn"),
            key: "sign-in",
          });
        })
        .catch((error) => {
          if (
            error.status === 401 &&
            error?.data?.error?.message === "Your account has been deleted."
          ) {
            message.error({
              content: error.data.error.message,
              key: "sign-in",
            });
          } else {
            setIsPasswordIncorrect(true);
            message.error({
              content: t("error.passwordOrUsernameIncorrect"),
              key: "sign-in",
            });
          }
        });
    },
    [setIsPasswordIncorrect, logIn, navigate, t, joinFleetSlug]
  );

  const renderForm = useCallback(
    ({ values, errors, onChange }) => {
      return (
        <>
          <Field label={t("field.email")} htmlFor="email" error={errors.email}>
            <Input
              id="email"
              name="email"
              type="email"
              value={values.email}
              onChange={onChange}
            />
          </Field>
          <Field
            label={t("field.password")}
            htmlFor="password"
            error={
              isPasswordIncorrect
                ? t("error.passwordOrUsernameIncorrect")
                : errors.password
            }
          >
            <FormPasswordInput
              id="password"
              name="password"
              value={values.password}
              onChange={onChange}
            />
          </Field>
          <Field>
            <Button
              block
              uppercase
              type="submit"
              variant="primary"
              shape="rounded"
            >
              {t("action.signIn")}
            </Button>
          </Field>
          <Field>
            <Button
              block
              to={routes.forgetPassword.getURL(values.email)}
              type="button"
              shape="rounded"
            >
              {t("action.clickHereIfYouForgotPassword")}
            </Button>
          </Field>
        </>
      );
    },
    [t, isPasswordIncorrect]
  );

  const validationRules = useMemo(() => {
    return [
      {
        field: "email",
        isValid: (value) => typeof value === "string" && value.length > 0,
        error: t("error.emailRequired"),
      },
      {
        field: "email",
        isValid: (value) => emailRegex.test(value),
        error: t("error.emailInvalid"),
      },
      {
        field: "password",
        isValid: (value) => typeof value === "string" && value.length > 0,
        error: t("error.passwordRequired"),
      },
    ];
  }, [t]);

  return (
    <Form
      initialValues={initialValues}
      validationRules={validationRules}
      onSubmit={onSubmit}
      noValidate
    >
      {renderForm}
    </Form>
  );
}

const initialValues = {
  email: "",
  password: "",
};
