import { useTranslation } from "react-i18next";

export const Breakdown = ({
  paymentType = "",
  currency,
  amount,
  percentage,
}) => {
  const { t } = useTranslation();
  const paymentTypeNormalized = paymentType.toLowerCase();
  const isPaymentTypeKnown = ["cash", "card", "invoice"].includes(
    paymentTypeNormalized
  );

  return (
    <div className="breakdown-content">
      <span>
        <h3>
          {isPaymentTypeKnown
            ? t(`optionPaymentType.${paymentTypeNormalized}`)
            : paymentType}
        </h3>
        <h3>
          {currency}
          {amount}
        </h3>
      </span>
      <div>
        <h4>{t("pageHome.percentageOfAllEarnings")}</h4>
        <h4>{percentage}%</h4>
      </div>
    </div>
  );
};
