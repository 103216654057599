import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { api } from "api";
import { routes } from "routes";

import { useEffectOnce } from "hooks/useEffectOnce";
import { useAuth } from "hooks/useAuth";

import { LoadingComp } from "components/LoadingComp";

export const EmailVerificationPage = () => {
  const navigate = useNavigate();

  const { user } = useAuth();
  const fleetId = user?.intent?.driver_signup?.fleet_id;

  const { data: fleetPublicData } =
    api.endpoints.getFleetPublicInfoById.useQuery(
      {
        fleetId,
      },
      { skip: fleetId === undefined }
    );
  const { url_slug: fleetSlug } = fleetPublicData?.data ?? {};

  const [searchParams] = useSearchParams();
  const emailUrl = searchParams.get("url");

  const [verifyEmail, { data, isError }] =
    api.endpoints.verifyEmail.useMutation();

  useEffectOnce(() => {
    verifyEmail({ emailUrl });
  }, []);

  useEffect(() => {
    if (fleetSlug === undefined) return;

    if (data?.status === "verified") {
      return navigate(routes.onboardingCreateDriverProfilePage.getURL(fleetSlug));
    }

    if (isError) {
      return navigate(routes.checkEmail.getURL(fleetSlug));
    }
  }, [data, fleetSlug, isError, navigate]);

  return <LoadingComp loading fullScreen />;
};
