import { isCurrency } from "./isCurrency";

/**
 * Formats currency according to locale standards.
 *
 * @param {number} amount - the number to format with proper placement of the decimal point
 * @param {string} [currency] - ISO 4217 currency code or undefined
 * @returns {string}
 */
export function formatCurrency(amount, currency) {
  const { format } = new Intl.NumberFormat(
    DEFAULT_LOCALE,
    getOptions(currency)
  );

  return isCurrency(currency) ? format(amount) : `--${format(amount)}`;
}

/**
 * Returns {@link NumberFormatOptions} for general-purpose currency formatter.
 *
 * @param {string | undefined} currency - ISO 4217 currency code or undefined if no currency is specified
 * @returns {NumberFormatOptions}
 */
function getOptions(currency) {
  if (isCurrency(currency)) {
    /**
     * `minimumFractionDigits` and `maximumFractionDigits` are provided
     * by the browser using the `currency` value
     */
    return {
      currency,
      style: "currency",
      currencyDisplay: "narrowSymbol",
    };
  }

  return {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
}

/**
 * Providing `undefined` as a locale makes {@link Intl.NumberFormat} use browser locale.
 * @type {undefined}
 */
const DEFAULT_LOCALE = undefined;
