export const generateListApiParams = (
  id,
  idKeyName,
  withPagination = true,
  page = 1,
  per_page = 25,
  otherParams
) => {
  return {
    ...(id && {
      [idKeyName]: id,
    }),
    ...(withPagination && {
      page,
      per_page,
    }),
    ...otherParams,
  };
};
