import clsx from "clsx";

import { Label } from "components/Label";
import { Small } from "components/Small";
import { Error } from "components/Error";

import styles from "./Field.module.scss";

export function Field({
  label,
  htmlFor,
  description,
  error,
  children,
  dense = false,
}) {
  return (
    <div className={clsx(styles.field, {
      [styles.extraSpace]: !dense
    })}>
      {label !== undefined ? (
        <div className={styles.captions}>
          <Label htmlFor={htmlFor}>{label}</Label>
        </div>
      ) : null}

      <div>{children}</div>

      {error !== undefined ? (
        <div className={styles.description}>
          <Error>{error}</Error>
        </div>
      ) : null}

      {description !== undefined ? (
        <div className={styles.description}>
          <Small>{description}</Small>
        </div>
      ) : null}
    </div>
  );
}
