import { useEffect } from "react";
import { Alert, Button, Form, Input, Select } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "api";
import { addCompany } from "store/slices/companies";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../helpers/windowMassages";
import {
  countries,
  formItemData,
  formItemDataKeys,
  validateMessages,
} from "./formData";

const { Option } = Select;

export const FleetForm = ({ fleet, fleetId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: integrationsData } = api.endpoints.getFleetIntegrations.useQuery();
  const integrations = integrationsData?.data || [];
  const [createEditFleet] = api.endpoints.createEditFleet.useMutation();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const currency = countries?.find(
      ({ id }) => id === values.country
    )?.currency;
    const parsedValues = {
      ...values,
      currency,
    };

    showLoadingMsg({ content: fleetId ? "Updating" : "Creating" });
    const method = fleetId ? "PATCH" : "POST";
    createEditFleet({
      fleetId,
      data: parsedValues,
      method,
    })
      .unwrap()
      .then(() => {
        showSuccessMsg();
        if (!fleetId) {
          form.resetFields();
          dispatch(addCompany(fleet));
          navigate("/console/admin");
        }
      })
      .catch(({ data }) => {
        const errors = data?.error?.errors;
        if (errors) {
          Object.keys(errors).forEach((key) => {
            if (formItemDataKeys[key]) {
              form.setFields([
                {
                  name: key,
                  errors: errors[key],
                },
              ]);
            }
          });
        }
        showErrorMsg();
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      name: fleet?.name,
      website: fleet?.website,
      url: fleet?.url,
      phone_number: fleet?.phone_number,
      email: fleet?.email,
      country: fleet?.country,
      fleet_integration_id: fleet?.integration?.id,
      stripe_account_id: fleet?.stripe_account_id,
      dispatch_company_id: fleet?.dispatch_company_id,
    });
  }, [fleet]);

  return (
    <div className="fleet-form-wrapper">
      <Alert
        message="Please complete your details below to start managing your driver payments."
        type="info"
        banner
      />
      <Form
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        form={form}
        validateMessages={validateMessages}
      >
        {formItemData.map(
          (
            {
              name,
              label,
              type,
              rules,
              optionsKeyName,
              appKeyNote,
              appSecretNote,
            },
            key
          ) => {
            const stateName =
              optionsKeyName == "integrations" ? integrations : countries;
            const keyCode = appKeyNote ? fleet?.app_key : fleet?.app_secret;

            return (
              <Form.Item
                {...{ name, label, type, rules, key }}
                extra={
                  (appKeyNote || appSecretNote) &&
                  `Note: Current value is ${keyCode}`
                }
              >
                {type === "password" ? (
                  <Input.Password autoComplete="new-password" />
                ) : type === "select" ? (
                  <Select>
                    {stateName.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Input />
                )}
              </Form.Item>
            );
          }
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {fleetId ? "save" : "create"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FleetForm;
