import { LoadingComp } from "components/LoadingComp";
import { MobileTableItem } from "components/MobileTableItem";
import { useIsMobile } from "hooks/useMobile";
import { useNavigate } from "react-router-dom";
import { CustomDropdown } from "components/CustomDropdown";
import { headerItems, options } from "./tableData";

export const Table = ({
  companies,
  loading
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const onDriverSelect = (id) => {
    navigate(`/console/fleets/${id}`);
  };

  return (
    <div className="companies-table-wrapper">
      <div className="companies-table-container" >
        {!isMobile && (
          <div className="header">
            {headerItems.map((item, index) => {
              return <div key={index}>{item.toUpperCase()}</div>;
            })}
          </div>
        )}
        <div>
          <LoadingComp loading={loading}>
            {isMobile ? (
              <>
                {companies.map((company) => {
                  const {
                    id,
                    name,
                    users_count,
                    drivers_count,
                    stripe_account_id
                  } = company;
                  return (
                    <div className="mobile-row" key={id}>
                      <MobileTableItem
                        fields={[
                          {
                            label: 'Name',
                            render: name
                          },
                          {
                            label: 'Stripe Enabled',
                            render: Boolean(stripe_account_id).toString()
                          },
                          {
                            label: 'Driver Pay Enabled',
                            render: 'true'
                          },
                          {
                            label: 'Number of users',
                            render: users_count
                          },
                          {
                            label: 'Number of drivers',
                            render: drivers_count
                          }
                        ]}
                        actions={(
                          <CustomDropdown
                            options={options}
                            onItemSelect={() => onDriverSelect(id)}
                          />
                        )}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {companies.map((company) => {
                  const {
                    id,
                    name,
                    users_count,
                    drivers_count,
                    stripe_account_id
                  } = company;
                  return (
                    <div className="row" key={id}>
                      <div className="cell">{name}</div>
                      <div className="cell">{Boolean(stripe_account_id).toString()}</div>
                      <div className="cell">true</div>
                      <div className="cell">{users_count}</div>
                      <div className="cell">{drivers_count}</div>
                      <div className="cell">
                        <CustomDropdown
                          options={options}
                          onItemSelect={() => onDriverSelect(id)}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {/* TODO: Here will be implemented infinity scroll */}
          </LoadingComp>
        </div>
      </div>
    </div>
  );
};

export default Table;
