import { useEffect, useState } from "react";
import { api } from "api";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useFeatureFlagsFromSearchParams } from "hooks/useFeatureFlagsFromSearchParams";

import { ModalLatestUserAgreementVersionNotAccepted } from "./ModalLatestUserAgreementVersionNotAccepted";

export function AcceptedLatestUserAgreementVersionRequired({ children }) {
  const [open, setOpen] = useState(false);
  const {
    releasePromptTermsAndConditions:
      releasePromptTermsAndConditionsFromLaunchDarkly,
  } = useFlags();
  const {
    isReleasePromptTermsAndConditionsEnabled:
      releasePromptTermsAndConditionsFromSearchParams,
  } = useFeatureFlagsFromSearchParams();

  const promptTermsAndConditions =
    releasePromptTermsAndConditionsFromLaunchDarkly ||
    releasePromptTermsAndConditionsFromSearchParams;

  const { data, isLoading } =
    api.endpoints.checkLatestUserAgreementVersion.useQuery(undefined, {
      skip: !promptTermsAndConditions,
    });
  const { accepted } = data ?? {};

  useEffect(() => {
    if (isLoading || typeof accepted === "undefined") {
      return;
    }

    const shouldBeOpen = !accepted && !open;
    if (shouldBeOpen) {
      setOpen(true);
    }

    const shouldNotBeOpen = accepted && open;
    if (shouldNotBeOpen) {
      setOpen(false);
    }
  }, [isLoading, accepted, open, setOpen]);

  const [acceptLatestUserAgreementVersion] =
    api.endpoints.acceptLatestUserAgreementVersion.useMutation();

  return (
    <>
      <ModalLatestUserAgreementVersionNotAccepted
        open={open}
        onSubmit={acceptLatestUserAgreementVersion}
      />
      {children}
    </>
  );
}
