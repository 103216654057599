import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import { Field } from "components/Field";
import { Title } from "components/Title";
import { Button } from "components/Button";
import { FormPasswordInput } from "components/FormPasswordInput/FormPasswordInput";

import styles from "./EnterPasswordToContinueModal.module.scss";

export function EnterPasswordToContinueModal({
  visible,
  incorrect,
  loading,
  onCancel,
  onSubmit,
}) {
  const { t } = useTranslation();

  function onSubmitInternal(event) {
    event.preventDefault();
    onSubmit({ password: event.target.elements.password.value });
  }

  return (
    <Modal
      visible={visible}
      centered
      closable={false}
      footer={null}
      onCancel={onCancel}
      className={styles.enterPasswordToContinueModal}
    >
      <form onSubmit={onSubmitInternal}>
        <Title>{t("modalEnterPasswordToContinue.enterYourPasswordToContinue")}</Title>
        <Field
          label={t("field.password")}
          htmlFor="password"
          error={incorrect ? t("error.passwordIncorrect") : undefined}
        >
          <FormPasswordInput id="password" name="password" />
        </Field>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: 20 }}>
          <Button shape="rounded" onClick={onCancel}>
            {t("action.cancel")}
          </Button>
          <Button
            variant="primary"
            shape="rounded"
            type="submit"
            loading={loading}
            disabled={loading}
          >
            {t("action.submit")}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
