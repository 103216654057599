import clsx from "clsx";
import styles from "./Container.module.scss";

export function Container({ width = "medium", ...otherProps }) {
  const className = clsx(styles.container, getWidth(width));
  return <div {...otherProps} className={className} />;
}

function getWidth(width) {
  const widthOptions = {
    medium: styles.widthMedium,
    small: styles.widthSmall,
  };

  if (!widthOptions.hasOwnProperty(width)) {
    console.warn(
      `<Container>'s width may be "medium" or "small", you have provided "${width}". "medium" is used as a fallback.`
    );
    return widthOptions.medium;
  }

  return widthOptions[width];
}
