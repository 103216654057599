import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ClickAwayListener from "react-click-away-listener";

import { Layout } from "antd";
import clsx from "clsx";

import useRoles from "hooks/useRoles";
import { setMenuCollapsed } from "store/slices/menuSlice";
import { useLogout } from "hooks/useLogout";
import SideBarIcons from "assets/icons/Sidebar";
import { nameFirstDigits } from "helpers/nameFirstDigits";

import CompanySelector from "components/CompanySelector/CompanySelectorDriverOnly";
import { DriverNavigation } from "components/Navigation";
import { ConfirmationModal } from "components/ConfirmationModal";

import { useIsMobile } from "./useIsMobile";

const { Sider } = Layout;

export function DriverSidebar() {
  const { user } = useRoles();

  const { isMenuCollapsed: collapsed } = useSelector(
    (state) => state.menuSlice
  );

  const isMobile = useIsMobile();

  const dispatch = useDispatch();
  const toggleCollapsed = () => {
    dispatch(setMenuCollapsed(!collapsed));
  };
  const toggleCollapsedIfMobile = () => {
    if (isMobile) {
      toggleCollapsed();
    }
  };

  const { t } = useTranslation();

  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const logOut = useLogout();

  const trigger = collapsed ? (
    <SideBarIcons.CompactArrow />
  ) : (
    <SideBarIcons.ExpandArrow />
  );

  const showChildren = isMobile ? !collapsed : true;

  return (
    <>
      {isMobile && !collapsed ? <div className="sider-backdrop" /> : null}

      <Sider
        collapsible
        theme="light"
        collapsed={collapsed}
        breakpoint={"lg"}
        trigger={trigger}
        onCollapse={toggleCollapsed}
        collapsedWidth={isMobile ? 0 : 63}
        className={clsx(isMobile && "mobile-aside")}
        width={isMobile ? 280 : 200}
      >
        {showChildren ? (
          <ClickAwayListener onClickAway={toggleCollapsedIfMobile}>
            <div
              className={clsx("sider-children", {
                "sider-collapsed": !isMobile && collapsed,
              })}
            >
              <div className="sidebar-top-content">
                <CompanySelector isWallet isDrawerClosed={collapsed} />
                <div
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    maxWidth: isMobile ? "auto" : "200px",
                  }}
                >
                  <DriverNavigation
                    collapsed={collapsed}
                    onLogoutClick={() => {
                      setLogoutModalVisible(true);
                    }}
                  />
                </div>
              </div>
              <div className="sidebar-bottom">
                <div className={collapsed ? "user-compact" : "user-expand"}>
                  <div className="name-in-circle">
                    {nameFirstDigits(user?.name)}
                  </div>
                  {!collapsed ? (
                    <h5 className="full-name">{user?.name}</h5>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </ClickAwayListener>
        ) : null}
      </Sider>

      <ConfirmationModal
        visible={logoutModalVisible}
        onCancel={() => {
          setLogoutModalVisible(false);
          toggleCollapsedIfMobile();
        }}
        onConfirm={logOut}
        okText={t("action.signOut")}
        cancelText={t("action.cancel")}
      >
        {t("modalYouSureToSignOut.areYouSureToSignOut")}
      </ConfirmationModal>
    </>
  );
}
