import { useTranslation } from "react-i18next";
import { FilterDropdown } from "components/FilterDropdown";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { api } from "api";
import { Breakdown } from "./Breakdown";
import { getEarnings } from "store/slices/driverEarnings";
import { handleEarningFilters } from "store/slices/earningsFilters";
import { useDispatch, useSelector } from "react-redux";
import { LoadingComp } from "components/LoadingComp";
import { twoDecimals } from "helpers/twoDecimal";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { format } from "date-fns";
import { useIsMobile } from "hooks/useMobile";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { useMediaQuery } from "hooks/useMediaQuery";

export const Earnings = ({ driverProfileId }) => {
  const isTablet = useMediaQuery("(min-width: 577px) and (max-width: 992px)");
  const chartWidthCompensation = isTablet ? -200 : 0;

  const chartWrapperElementRef = useRef(null);
  const { t } = useTranslation();

  const paymentTypeOptions = [
    {
      value: "CARD",
      label: t("optionPaymentType.card"),
    },
    {
      value: "INVOICE",
      label: t("optionPaymentType.invoice"),
    },
    {
      value: "CASH",
      label: t("optionPaymentType.cash"),
    },
  ];

  const paymentTimeFrameOptions = [
    { value: "today", label: t("optionDataRange.today") },
    { value: "this_week", label: t("optionDataRange.thisWeek") },
    { value: "this_month", label: t("optionDataRange.thisMonth") },
  ];

  const paymentTimeFrameOptionsMap = {
    today: t("optionDataRange.today"),
    this_week: t("optionDataRange.thisWeek"),
    this_month: t("optionDataRange.thisMonth"),
  };

  const isMobile = useIsMobile();
  const { selectedFleetCurrency } = useSelectedFleet();

  const { earnings } = useSelector((state) => state.earnings);

  const {
    earningsFiltersData: { payment_types, timeframe },
  } = useSelector((state) => state.earningsFilters);

  const dispatch = useDispatch();

  const [driverEarnings] = api.endpoints.getDriverEarnings.useMutation();

  const onPaymentTypeSelect = useCallback(
    ({ name, entries }) => {
      dispatch(handleEarningFilters({ type: name, item: entries }));
    },
    [dispatch]
  );

  const onTimeFrameSelect = useCallback(
    (item) => {
      dispatch(handleEarningFilters({ type: "timeframe", item }));
    },
    [dispatch]
  );

  const totalEarnings =
    (earnings || []).reduce((prevValue, currentValue) => {
      return prevValue + currentValue.total;
    }, 0) / 100;

  useEffect(() => {
    if (driverProfileId) {
      driverEarnings({
        driverProfileId,
        payment_types,
        timeframe,
      })
        .unwrap()
        .then((res) => {
          dispatch(getEarnings(res));
        })
        .catch((err) => {});
    }
  }, [dispatch, driverEarnings, driverProfileId, payment_types, timeframe]);

  const earningsChartData = useMemo(() => {
    if (!earnings) {
      return [];
    }

    const dateFormat = timeframe === "today" ? "HH:mm" : "MM-dd-yyyy";

    const earningsMap = earnings.map((earning) => {
      return earning.breakdown.reduce((prev, curr) => {
        return {
          ...prev,
          [format(new Date(curr.date), dateFormat)]: {
            amt: twoDecimals(curr.amount / 100),
            type: earning.payment_type,
          },
        };
      }, {});
    });

    const chartData = [];

    if (!earningsMap.length) {
      return [];
    }

    Object.keys(earningsMap[0]).forEach((key) => {
      const data = {
        amt: key,
        name: key,
      };

      earningsMap.forEach((map) => {
        const currentEarning = map[key];

        data[currentEarning.type] = currentEarning.amt;
      });

      chartData.push(data);
    });

    return chartData;
  }, [earnings, timeframe]);

  const areAllPaymentTypesSelected =
    payment_types.length === paymentTypeOptions.length;

  const earningsAmountMap = useMemo(() => {
    if (!earnings) {
      return [];
    }

    const map = earnings.reduce((prevValues, currValue) => {
      return {
        ...prevValues,
        [currValue.payment_type]: currValue.breakdown.reduce(
          (prevValue, currValue) => prevValue + currValue.amount,
          0
        ),
      };
    }, {});

    const allEarnings = Object.values(map).reduce(
      (prevValues, currValue) => prevValues + currValue,
      0
    );

    return Object.entries(map).reduce((prevValues, [key, currentValue]) => {
      return {
        ...prevValues,
        [key]: {
          ...currentValue,
          percentage:
            Math.round(
              currentValue === 0 ? 0 : (currentValue / allEarnings) * 1000
            ) / 10,
        },
      };
    }, {});
  }, [earnings]);

  const filters = useMemo(() => {
    const paymentTypeLabel = isMobile
      ? areAllPaymentTypesSelected
        ? t("pageHome.all")
        : payment_types.join(" + ")
      : t("pageHome.paymentTypes");

    return (
      <div className="filters">
        <FilterDropdown
          btnLabel={paymentTypeLabel}
          name="payment_types"
          options={paymentTypeOptions}
          onChange={onPaymentTypeSelect}
          initialVal={payment_types}
          btnLabelMobile={t("pageHome.filterByPaymentMethod")}
        />

        <FilterDropdown
          btnLabel={paymentTimeFrameOptionsMap[timeframe]}
          name="payment_types"
          options={paymentTimeFrameOptions}
          onChange={onTimeFrameSelect}
          btnLabelMobile={t("pageHome.filterByTimeframe")}
          initialVal={timeframe}
          isSingular
        />
      </div>
    );
  }, [
    onPaymentTypeSelect,
    onTimeFrameSelect,
    payment_types,
    isMobile,
    timeframe,
    areAllPaymentTypesSelected,
    t,
  ]);

  return (
    <LoadingComp loading={!earnings.length}>
      <div className="earnings-wrapper">
        <div className="earningsHeaderWrapper">
          <h1>{t("pageHome.earnings")}</h1>
          {isMobile && filters}
        </div>
        {!isMobile && filters}
        <h2>
          {selectedFleetCurrency}
          {twoDecimals(totalEarnings)}
        </h2>
        <div style={{ height: 300 }} ref={chartWrapperElementRef}>
          <ResponsiveContainer
            width={
              (chartWrapperElementRef?.current?.clientWidth ?? 0) +
              chartWidthCompensation
            }
          >
            <AreaChart data={earningsChartData}>
              <defs>
                <linearGradient id="colorCARD" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#47D061" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#47D061" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorINVOICE" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#51a3ee" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#51a3ee" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorCASH" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#aaaaaa" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#aaaaaa" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <Area
                dataKey="CARD"
                stroke="#47D061"
                fillOpacity={1}
                fill="url(#colorCARD)"
              />
              <Area
                dataKey="INVOICE"
                stroke="#51a3ee"
                fillOpacity={1}
                fill="url(#colorINVOICE)"
              />
              <Area
                dataKey="CASH"
                stroke="#aaaaaa"
                fillOpacity={1}
                fill="url(#colorCASH)"
              />
              <Tooltip />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className="breakdown">
          <h1>{t("pageHome.earningsBreakdown")}</h1>
          {earnings.map((item, index) => (
            <Breakdown
              currency={selectedFleetCurrency}
              amount={twoDecimals(item.total / 100)}
              paymentType={item.payment_type}
              key={index}
              percentage={
                earningsAmountMap?.[item.payment_type]?.percentage || 0
              }
            />
          ))}
        </div>
      </div>
    </LoadingComp>
  );
};
