import { useTranslation } from "react-i18next";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import {
  getDayDifference,
  getDayNumberByDayString,
} from "helpers/getDayDifference";

const CURRENT_DAY = new Date().getDay();

export function NextPayoutWidget() {
  const { t } = useTranslation();
  const { selectedFleet } = useSelectedFleet();

  const difference = getDayDifference(
    CURRENT_DAY,
    getDayNumberByDayString(selectedFleet?.payout_day)
  );

  return t("pageHome.nextPayout", { count: difference });
}
