import { format } from 'date-fns';

export const groupedNotificationsByDate = (data) => {
  if (!data) return;
  const groupByDate = data.reduce((group, notification) => {
    const { created_at } = notification;
    const formatedDate = format(new Date(created_at), "MMMM dd,yyyy");
    group[formatedDate] = group[formatedDate] ?? [];
    group[formatedDate].push(notification);
    return group;
  }, {});
  return Object.entries(groupByDate);
};
