import { useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import capitalize from "lodash/capitalize";
import { Searchbar } from "components/Searchbar";
import { CustomButton } from "components/CustomButton";
import { DateRangeInput } from "components/DateRangeInput";
import { DoubleDropdown } from "components/DoubleDropdown";
import { PAYMENT_METHOD, PAYMENT_STATUS } from "constants/index";

import { ReactComponent as ExportIcon } from "assets/icons/export.svg";
import { formatDate } from "api/endpoints/payouts";
import { api } from "api";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { differenceInDays } from "date-fns";

export const driverBookingsPaymentMethodsInitialValues =
  Object.values(PAYMENT_METHOD);

export const driverBookingsPaymentStatusInitialValues =
  Object.values(PAYMENT_STATUS);

export const Filters = ({
  dateRange,
  onDateRangeChange,
  onSearch,
  onClear,
  paymentMethodValue,
  paymentStatusValue,
  onPaymentMethodChange,
  onPaymentStatusChange,
}) => {
  const { t } = useTranslation();
  const { selectedDriverProfile } = useSelectedFleet();
  const selectedDriverProfileId = selectedDriverProfile?.id;

  const firstDropdownOptions = {
    name: t("field.paymentMethod"),
    options: [
      {
        label: t("optionPaymentType.card"),
        value: PAYMENT_METHOD.CARD,
      },
      {
        label: t("optionPaymentType.invoice"),
        value: PAYMENT_METHOD.INVOICE,
      },
      {
        label: t("optionPaymentType.cash"),
        value: PAYMENT_METHOD.CASH,
      },
    ],
  };

  const secondDropdownOptions = {
    name: t("field.paymentStatus"),
    options: [
      {
        label: t("optionPaymentStatus.pending"),
        value: PAYMENT_STATUS.PENDING,
      },
      {
        label: t("optionPaymentStatus.paid"),
        value: PAYMENT_STATUS.PAID,
      },
      {
        label: t("optionPaymentStatus.withheld"),
        value: PAYMENT_STATUS.CHARGEBACK,
      },
      {
        label: t("optionPaymentStatus.refunded"),
        value: PAYMENT_STATUS.REFUND,
      },
    ],
  };

  const isTablet = useMediaQuery({ query: "(max-width: 1180px)" });

  const paymentFiltersHeader = useMemo(() => {
    let paymentMethodHeader = null;
    if (paymentMethodValue) {
      if (
        paymentMethodValue.length ===
          driverBookingsPaymentMethodsInitialValues.length ||
        !paymentMethodValue.length
      ) {
        paymentMethodHeader = t("field.paymentMethod");
      } else {
        paymentMethodHeader = paymentMethodValue.map(capitalize).join(" - ");
      }
    }

    return {
      paymentMethodHeader,
      paymentStatusFilter: t("field.paymentStatus"),
    };
  }, [paymentMethodValue, t]);

  const [postBookingsReport] = api.endpoints.postBookingsReport.useMutation();

  const bookingDifferenceInDays = differenceInDays(dateRange[1], dateRange[0]);
  const notDisabled =
    bookingDifferenceInDays >= 0 && bookingDifferenceInDays <= 90;

  const [isExportingButtonDisabled, setExportingButtonDisabled] = useState(
    !notDisabled
  );

  const sendRequest = () => {
    postBookingsReport({
      id: selectedDriverProfileId,
      data: {
        date_from: formatDate(dateRange[0]),
        date_to: formatDate(dateRange[1]),
      },
    });

    setExportingButtonDisabled(true);
    let time = setTimeout(() => {
      setExportingButtonDisabled(!notDisabled);
      clearTimeout(time);
    }, 3000);
  };

  const tabletFilters = useMemo(() => {
    if (isTablet) {
      return (
        <div className="driver-filter-mobile">
          <Searchbar
            onPressEnter={onSearch}
            onChange={({ target }) => onClear(target.value)}
            placeholder={t("field.bookingId")}
            allowClear
          />
          <CustomButton
            onClick={sendRequest}
            Icon={<ExportIcon />}
            disabled={isExportingButtonDisabled}
          />
        </div>
      );
    } else {
      return (
        <Searchbar
          onPressEnter={onSearch}
          onChange={({ target }) => onClear(target.value)}
          placeholder={t("field.bookingId")}
          allowClear
        />
      );
    }
  }, [isTablet, onClear, onSearch, t]);

  return (
    <div className="driver-bookings-filters">
      {tabletFilters}
      <DoubleDropdown
        firstOptions={firstDropdownOptions}
        secondOptions={secondDropdownOptions}
        firstOptionInitialValue={driverBookingsPaymentMethodsInitialValues}
        secondOptionInitialValue={driverBookingsPaymentStatusInitialValues}
        firstOptionValues={paymentMethodValue}
        secondOptionValues={paymentStatusValue}
        onFirstOptionChange={onPaymentMethodChange}
        onSecondOptionChange={onPaymentStatusChange}
        sendEmptyWhenAllChecked
        firstOptionHeader={paymentFiltersHeader.paymentMethodHeader}
        secondOptionHeader={paymentFiltersHeader.paymentStatusFilter}
        wrapperClassName="double-dropdown"
      />
      <DateRangeInput value={dateRange} onChange={onDateRangeChange} />
      {!isTablet && (
        <CustomButton
          onClick={sendRequest}
          Icon={<ExportIcon />}
          disabled={isExportingButtonDisabled}
        />
      )}
    </div>
  );
};
