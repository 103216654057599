import { Input } from "antd";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import "./Searchbar.scss";

export const Searchbar = ({
  placeholder,
  prefix = <SearchIcon />,
  allowClear = false,
  classname,
  onPressEnter = () => {},
  onChange = () => {},
}) => {
  return (
    <Input
      className={`custom-input ${classname}`.trim()}
      placeholder={placeholder}
      prefix={prefix}
      allowClear={allowClear}
      onPressEnter={onPressEnter}
      onChange={onChange}
    />
  );
};
