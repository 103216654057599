import { twoDecimals } from "helpers/twoDecimal";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { useTranslation } from "react-i18next";
import { useFlags } from "launchdarkly-react-client-sdk";

import { BookingItem } from "../BookingItem";
import { calculateDriverCommissionValue } from "helpers/calculateDriverCommissionValue";

export const GroupedBookings = ({ group }) => {
  const { t } = useTranslation();
  const { releaseDeductDriverCommission } = useFlags();
  const { selectedFleetCurrency } = useSelectedFleet();

  const totalAmount = group.items.reduce((total, item) => {
    const totalCostDividedBy100 = (item?.total_cost ?? 0) / 100;
    const commissionValue = calculateDriverCommissionValue(
      totalCostDividedBy100,
      item?.driver_commission,
      releaseDeductDriverCommission
    );

    return total + (totalCostDividedBy100 - commissionValue);
  }, 0);

  const label =
    group.todayLabel === "started"
      ? t("pageBooking.startedUsingServiceSuffix")
      : group.todayLabel === "stopped"
      ? t("pageBooking.stoppedUsingServiceSuffix")
      : null;

  return (
    <div className="booking-group">
      <div className="header">
        <h1>
          {group.title}
          {label}
        </h1>
        <h1>
          {selectedFleetCurrency}
          {twoDecimals(totalAmount)}
        </h1>
      </div>
      <div className="booking-item-wrapper">
        {group.items.map((booking) => (
          <BookingItem
            key={booking.id}
            currency={selectedFleetCurrency}
            booking={booking}
          />
        ))}
      </div>
    </div>
  );
};
