import { Badge, Button, Input, Tabs } from "antd";
import { MenuToggle } from "components/MenuToggle/MenuToggle";
import { showErrorMsg } from "helpers/windowMassages";
import { useIsMobile } from "hooks/useMobile";
import useRoles from "hooks/useRoles";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "api";
import { ReactComponent as AddIcon } from "../../../assets/icons/addIcon.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import SideBarIcons from "../../../assets/icons/Sidebar";
import "./FleetsList.scss";
import Settings from "./Settings/Settings";
import Table from "./Table/Table";

const { TabPane } = Tabs;

export const FleetsList = () => {
  const { isSuperAdmin } = useRoles();
  let navigate = useNavigate();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isSuperAdmin) {
      navigate('/');
      showErrorMsg({
        content: 'You are not authorized to access this page'
      })
    }
  }, [isSuperAdmin, navigate]);

  const [searchInput, setSearchInput] = useState('');
  const shouldQueryWithParams = searchInput.length >= 3;
  const getFleetsQueryArgument = shouldQueryWithParams
    ? {
        params: {
          term: searchInput,
        },
      }
    : undefined;
  const { data: companiesData, isLoading, isFetching } = api.endpoints.getFleets.useQuery(getFleetsQueryArgument);
  const companies = companiesData?.data || [];

  const showLoading = isLoading || isFetching;

  const tabContent = <Badge count={companies.length}>Companies</Badge>;
  const onSearch = (e) => {
    const inputValue = e.target.value;
    if (!inputValue.length) {
      setSearchInput('');
      return;
    }
    if (inputValue.length >= 3) {
      setSearchInput(inputValue);
    }
  };
  const onTabsHandle = (key) => {
    // console.log(key);
  };
  const newCompanyRedirect = () => {
    navigate("/console/fleets/new");
  };
  return (
    <div className="fleets-list-wrapper">
      <div className="header">
        <div className="title-section">
          <div>
            {isMobile ? (
              <MenuToggle />
            ) : (
              <SideBarIcons.PaymentSettIcon />
            )}
          </div>
          <h1>Admin</h1>
        </div>

        <div className="action-section">
          <Input
            placeholder="Search for a company"
            prefix={<SearchIcon />}
            onPressEnter={onSearch}
          />
          <Button icon={<AddIcon />} onClick={newCompanyRedirect}>
            New Company
          </Button>
        </div>
      </div>
      <Tabs defaultActiveKey="1" onChange={onTabsHandle}>
        <TabPane key="1" tab={tabContent}>
          <Table loading={showLoading} companies={companies} />
        </TabPane>
        <TabPane className="settings-tab" tab="Admin settings" key="2">
          <Settings />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default FleetsList;
