import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMenuCollapsed: true
};

export const menuSlice = createSlice({
  name: "menuSlice",
  initialState,
  reducers: {
    setMenuCollapsed: (state, action) => {
      state.isMenuCollapsed = action.payload;
    }
  },
});

export const { setMenuCollapsed } = menuSlice.actions;

export default menuSlice.reducer;
