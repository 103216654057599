import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export function useFeatureFlagsFromSearchParams() {
  const [searchParams] = useSearchParams();

  return useMemo(
    function extractFeaturesFlags() {
      const defaultFlags = {
        isSignatureEnabled: false,
        isOverrideVehicleLookupServiceManualEnabled: false,
        isOverrideVehicleLookupServiceDvlaEnabled: false,
        isPayoutsTableOnHomePageEnabled: false,
        isNewDocumentStatusIconsEnabled: false,
        isReleasePromptTermsAndConditionsEnabled: false,
        isReleaseRequireTermsAndConditionsOnJoiningEnabled: false,
        isInsurerFieldReleased: false,
      };

      const isFeatureFlagsParamMissing =
        searchParams.get("feature_flags") === null;

      if (isFeatureFlagsParamMissing) {
        return defaultFlags;
      }

      const featureFlags = searchParams.get("feature_flags").split(",");
      return {
        ...defaultFlags,
        isSignatureEnabled: featureFlags.includes("signature"),
        isOverrideVehicleLookupServiceManualEnabled: featureFlags.includes(
          "override-vehicle_lookup_service-to-manual"
        ),
        isOverrideVehicleLookupServiceDvlaEnabled: featureFlags.includes(
          "override-vehicle_lookup_service-to-dvla"
        ),
        isPayoutsTableOnHomePageEnabled: featureFlags.includes("payouts-table"),
        isNewDocumentStatusIconsEnabled: featureFlags.includes(
          "new-table-document-status-icons"
        ),
        isReleasePromptTermsAndConditionsEnabled: featureFlags.includes(
          "release-prompt-terms-and-conditions"
        ),
        isReleaseRequireTermsAndConditionsOnJoiningEnabled:
          featureFlags.includes(
            "release-required-terms-and-conditions-on-joining"
          ),
        isInsurerFieldReleased: featureFlags.includes("release-insurer-field"),
      };
    },
    [searchParams]
  );
}
