import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { api } from "api";

import { Field } from "components/Field";
import { Button } from "components/Button";

import { EnterPasswordToContinueModal } from "./EnterPasswordToContinueModal";
import { BankDetailsModal } from "./BankDetailsModal";

const Step = Object.freeze({
  IDLE: "IDLE",
  PASSWORD_NEEDED: "PASSWORD_NEEDED",
  DETAILS_VISIBLE: "DETAILS_VISIBLE",
});
const EMPTY_OBJECT = {};

export function BankDetailsSection({ profile }) {
  const { t } = useTranslation();

  const [step, setStep] = useState(Step.IDLE);
  const [password, setPassword] = useState("");

  const [getStripeBankAccount, { data, error, isLoading, isSuccess, reset }] =
    api.endpoints.getStripeBankAccounts.useMutation();

  const [
    createStripeBankAccount,
    {
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      reset: resetCreate,
    },
  ] = api.endpoints.createStripeBankAccounts.useMutation();

  const [
    deleteStripeBankAccount,
    {
      error: deleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      reset: resetDelete,
    },
  ] = api.endpoints.deleteStripeBankAccounts.useMutation();

  const bankDetails = data?.data?.[0] ?? EMPTY_OBJECT;

  useEffect(() => {
    if (!isSuccess || step === Step.IDLE) {
      return;
    }

    setStep(Step.DETAILS_VISIBLE);
  }, [isSuccess, setStep, step, data]);

  useEffect(() => {
    if (
      isCreateSuccess &&
      !isDeleteLoading &&
      (deleteError === undefined || deleteError === null)
    ) {
      deleteStripeBankAccount({
        password,
        driverProfileId: profile.id,
        bankAccountId: bankDetails.id,
      });
    }
  }, [
    isCreateSuccess,
    isDeleteLoading,
    deleteStripeBankAccount,
    profile,
    bankDetails,
    password,
    deleteError,
  ]);

  useEffect(() => {
    if (deleteError !== null && deleteError !== undefined) {
      message.error(deleteError?.data?.message);
      setStep(Step.IDLE);
      reset();
      resetCreate();
      resetDelete();
    }
  }, [deleteError, reset, resetCreate, resetDelete]);

  useEffect(() => {
    if (isDeleteSuccess) {
      message.success(t("success.bankDetailsUpdated"));
      setStep(Step.IDLE);
      reset();
      resetCreate();
      resetDelete();
    }
  }, [isDeleteSuccess, reset, resetCreate, resetDelete, t]);

  useEffect(() => {
    if (createError !== null && createError !== undefined) {
      message.error(createError?.data?.message);
    }
  }, [createError]);

  return (
    <Field label={t("field.bankDetails")}>
      <Button
        block
        uppercase
        variant="primary"
        shape="rounded"
        onClick={() => {
          if (isSuccess) {
            setStep(Step.DETAILS_VISIBLE);
            return;
          }
          setStep(Step.PASSWORD_NEEDED);
        }}
      >
        {t("action.updateBankDetails")}
      </Button>

      <EnterPasswordToContinueModal
        visible={step === Step.PASSWORD_NEEDED}
        incorrect={error?.status === 403}
        loading={isLoading}
        onSubmit={(value) => {
          setPassword(value.password);

          getStripeBankAccount({
            password: value.password,
            driverProfileId: profile.id,
          });
        }}
        onCancel={() => setStep(Step.IDLE)}
      />

      <BankDetailsModal
        visible={step === Step.DETAILS_VISIBLE}
        loading={isCreateLoading || isDeleteLoading}
        bankDetails={bankDetails}
        onCancel={() => setStep(Step.IDLE)}
        onSubmit={(values) => {
          createStripeBankAccount({
            driverProfileId: profile.id,
            country: values.country,
            currency: values.currency,
            account_number: values.accountNumber,
            routing_number: values.routingNumber,
          });
        }}
      />
    </Field>
  );
}
