import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import { routes } from "routes";

import { api } from "api";

import { Spacer } from "components/Spacer";
import { Title } from "components/Title";
import { Description } from "components/Description";
import { Button } from "components/Button";

import {
  usePredefinedDriverDocumentTypes,
  usePredefinedVehicleDocumentTypesToSubmit,
  ValidationWrapper,
} from "../../documents";

import {
  useCurrentDriverApplication,
  useLatestDocumentByStatusByType,
} from "../OnboardingBreadcrumbs";

export function SubmitApplicationPage() {
  const { t } = useTranslation();

  const { fleetSlug } = useParams();
  const driverApplication = useCurrentDriverApplication();
  const isApplicationCompleted = useApplicationCompleted();
  const isApplicationRejected = driverApplication?.status === "rejected";

  const navigate = useNavigate();

  const [
    patchDriverApplication,
    {
      isLoading: isPatching,
      isSuccess: isPatchingSucceed,
      isError: isPatchingFailed,
      reset,
    },
  ] = api.endpoints.patchDriverApplication.useMutation();

  useEffect(() => {
    if (isPatching) {
      message.loading({
        content: t("processing.applicationSubmitting"),
        duration: 0,
        key: "submitting_application",
      });
    } else {
      message.destroy("submitting_application");
    }
  }, [isPatching, t]);

  useEffect(() => {
    if (isPatchingFailed) {
      message.error({
        content: t("error.applicationSubmittingFailed"),
      });
    }

    if (isPatchingSucceed) {
      message.success({
        content: t("success.applicationSubmitted"),
      });
    }

    if (isPatchingSucceed || isPatchingFailed) {
      reset();
      message.destroy("submitting_application");
    }
  }, [isPatchingSucceed, isPatchingFailed, navigate, reset, t]);

  useEffect(() => {
    if (!isPatchingSucceed) return;
    navigate(routes.onboardingApplicationIsSubmitted.getURL(fleetSlug));
  }, [isPatchingSucceed, navigate, fleetSlug]);

  function onSubmit() {
    patchDriverApplication({
      driverApplicationId: driverApplication?.id,
      status: "new",
    });
  }

  if (isApplicationRejected) {
    const comment = driverApplication?.comment ?? "";

    return (
      <ValidationWrapper>
        <Title>{t("pageSubmitApplication.yourApplicationRejected")}</Title>
        <Description>
          <p>{t("pageSubmitApplication.thankYouButApplicationRejected")}</p>

          {comment.length > 0 ? (
            <p>{t("pageSubmitApplication.comment", { comment })}</p>
          ) : null}
        </Description>
      </ValidationWrapper>
    );
  }

  return (
    <ValidationWrapper>
      <Title>{t("pageSubmitApplication.submitApplication")}</Title>
      <Description>{t("pageSubmitApplication.youReceiveEmail")}</Description>
      <Spacer />

      {!isApplicationCompleted ? (
        <Description importance="error">
          {t("pageSubmitApplication.youSubmitIncompleteApplication")}
        </Description>
      ) : null}
      <Spacer />

      <Button
        block
        variant="primary"
        shape="round"
        fontWeight="bold"
        onClick={onSubmit}
      >
        {t("action.submitApplication")}
      </Button>
    </ValidationWrapper>
  );
}

function useApplicationCompleted() {
  const latestDocumentByStatusByType = useLatestDocumentByStatusByType();
  const driverApplication = useCurrentDriverApplication();
  const hasOwnVehicle = driverApplication?.vehicle_registration !== null;

  const predefinedDriverDocumentTypes = usePredefinedDriverDocumentTypes();

  const areDriverDocumentsReady = predefinedDriverDocumentTypes.every(
    (type) =>
      latestDocumentByStatusByType?.[type.id]?.last !== null &&
      latestDocumentByStatusByType?.[type.id]?.last !== undefined
  );

  const predefinedVehicleDocumentTypesToSubmit =
    usePredefinedVehicleDocumentTypesToSubmit();

  const areVehicleDocumentsReady = predefinedVehicleDocumentTypesToSubmit.every(
    (type) =>
      latestDocumentByStatusByType?.[type.id]?.last !== null &&
      latestDocumentByStatusByType?.[type.id]?.last !== undefined
  );

  if (!hasOwnVehicle) {
    return areDriverDocumentsReady;
  }

  return areDriverDocumentsReady && areVehicleDocumentsReady;
}
