import { useCallback } from "react";
import { Layout } from "antd";

import { useSelectedFleet } from "hooks/useSelectedFleet";
import { getIsStripeCompletedByDriverProfile } from "helpers/getIsStripeCompletedByDriverProfile";

import { PageHeader } from "components/PageHeader/PageHeader";

import { StripeConfirmationModal } from "components/StripeConfirmationModal";

import { BurgerSidebarTriggerButton } from "../BurgerSidebarTriggerButton";

import { useIsMobile } from "./useIsMobile";
import { DriverSidebar } from "./DriverSidebar";
import { StripeAlertBanner } from "./StripeAlertBanner";

import "./GeneralLayout.scss";

const { Content } = Layout;

export function GeneralLayout({ children }) {
  const { fleets, selectedFleet, selectedDriverProfile, selectFleetId } =
    useSelectedFleet();

  const isStripeConfirmationModalVisible = (() => {
    if (!selectedDriverProfile) {
      return false;
    }

    const isStripeCompleted = getIsStripeCompletedByDriverProfile(
      selectedDriverProfile
    );

    const isDriverPayEnabled = Boolean(selectedFleet?.enable_driver_pay);
    return isDriverPayEnabled && !isStripeCompleted;
  })();

  const handleConfirmationModalFleetChange = useCallback(
    (fleetId) => {
      if (fleetId) {
        selectFleetId(fleetId);
      }
    },
    [selectFleetId]
  );

  return (
    <>
      <Layout className="fleet-layout-wrapper">
        <div style={{ flexGrow: 1 }}>{children}</div>
        <DriverSidebar />
      </Layout>

      {selectedFleet && (
        <StripeConfirmationModal
          visible={isStripeConfirmationModalVisible}
          onFleetChange={handleConfirmationModalFleetChange}
          selectedFleet={selectedFleet}
          allFleets={fleets}
          driverProfileId={selectedDriverProfile?.id}
          driverProfile={selectedDriverProfile}
        />
      )}
    </>
  );
}

export function GeneralLayoutContent({ header, children }) {
  return (
    <Layout className="wallet-layout-wrapper">
      {header}

      <Layout className="content-wrapper wallet-content-wrapper">
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
}

export function GeneralLayoutHeader({ title, icon }) {
  const isMobile = useIsMobile();

  const { selectedDriverProfile, selectedFleet } = useSelectedFleet();

  const isDriverPayEnabled = Boolean(selectedFleet?.enable_driver_pay);

  const isStripeConnected =
    selectedDriverProfile?.stripe_details_submitted &&
    selectedDriverProfile?.stripe_payouts_enabled &&
    isDriverPayEnabled;

  const showStripeAlertBanner = Boolean(selectedDriverProfile);
  const stripeAlertBannerElement = showStripeAlertBanner ? (
    <StripeAlertBanner
      connected={isStripeConnected}
      enabled={isDriverPayEnabled}
    />
  ) : null;

  if (isMobile) {
    return (
      <div className="active-menu-indicator">
        <BurgerSidebarTriggerButton />
        <h1>{title}</h1>
        <span className="stripe-status-mobile">{stripeAlertBannerElement}</span>
      </div>
    );
  }

  return (
    <div className="wallet-layout-header">
      <PageHeader title={title} icon={icon}>
        {stripeAlertBannerElement}
      </PageHeader>
    </div>
  );
}
