import { Layout, Menu, Grid } from "antd";
import clsx from "clsx";
import useRoles from "hooks/useRoles";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideBarIcons from "../../../assets/icons/Sidebar";
import { ConfirmationModal } from "components/ConfirmationModal";
import CompanySelector from "../../../components/CompanySelector/CompanySelector";
import { nameFirstDigits } from "../../../helpers/nameFirstDigits";
import "./FleetLayout.scss";
import fleetMenuItems from "./sidebarItems";
import { useDispatch, useSelector } from "react-redux";
import { setMenuCollapsed } from 'store/slices/menuSlice';
import ClickAwayListener from "react-click-away-listener";
import { RedirectToCompany, useQueryCompanies } from "hooks/useQueryCompanies";
import { useLogout } from "hooks/useLogout";
import { routes } from "../../../routes";
const { useBreakpoint } = Grid;
const { Content, Sider } = Layout;

const FleetLayout = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { isDriver, isSuperAdmin, user } = useRoles();
  const logOut = useLogout();
  const screens = useBreakpoint();
  useQueryCompanies({ redirectTo: RedirectToCompany.ADMIN });

  const stringifiedBreakpoint = Object.keys(screens).filter((key) => screens[key]).join('-');
  const isMobile = stringifiedBreakpoint === 'xs' || stringifiedBreakpoint === 'xs-sm';

  const menuItems = useMemo(
    () => fleetMenuItems(isDriver, isSuperAdmin),
    [isDriver, isSuperAdmin]
  );
  const currentKey =
    fleetMenuItems(isDriver, isSuperAdmin).find((_item) =>
      location.pathname.startsWith(_item.key)
    )?.key || "/console/dashboard";
  const { isMenuCollapsed } = useSelector(state => state.menuSlice);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState(currentKey);

  const handleModalVisibility = () => setVisible((prevState) => !prevState);

  const closeMobileMenu = () => {
    dispatch(setMenuCollapsed(true));
  }

  const onCollapse = () => {
    if (isMobile) {
      closeMobileMenu()
      return;
    }
    setIsCollapsed((prevState) => !prevState);
  };

  const onItemSelect = ({ key }) => {
    if (key === "switch_wallet") {
      navigate(routes.walletHome.getURL());
      return;
    }

    if (key === 'signout') {
      handleModalVisibility();
      return;
    }

    if (isMobile) {
      closeMobileMenu();
    }
    navigate(key);
  };

  useEffect(() => {
    setSelectedKey(currentKey);
  }, [location]);

  const menuCondition = useMemo(() => {
    if (isMobile) {
      return isMenuCollapsed;
    } else {
      return isCollapsed;
    }
  }, [isCollapsed, isMenuCollapsed, isMobile]);

  const showSiderChildren = useMemo(() => {
    if (!isMobile) {
      return true;
    }

    return !isMenuCollapsed;
  }, [isMenuCollapsed, isMobile]);

  const siderChildrenClassName = useMemo(() => {
    if (isMobile || !isCollapsed) {
      return ''
    }

    return 'sider-collapsed'
  }, [isCollapsed, isMobile]);

  return (
    <>
      <Layout className="fleet-layout-wrapper">
        {!menuCondition && isMobile && (
          <div className="sider-backdrop" />
        )}
        <Layout className="content-wrapper">
          <Content>
            <Outlet />
          </Content>
        </Layout>

        <Sider
          collapsible
          theme="light"
          collapsed={menuCondition}
          breakpoint={"lg"}
          trigger={
            menuCondition ? (
              <SideBarIcons.CompactArrow />
            ) : (
              <SideBarIcons.ExpandArrow />
            )
          }
          onCollapse={() => onCollapse()}
          collapsedWidth={isMobile ? 0 : 63}
          className={clsx(isMobile && 'mobile-aside')}
          width={isMobile ? 280 : 200}
        >
          {showSiderChildren && (
            <ClickAwayListener onClickAway={closeMobileMenu} >
              <div className={clsx('sider-children', siderChildrenClassName)} >
                <div className="sidebar-top-content">
                  <CompanySelector isDrawerClosed={menuCondition} onCollapse={onCollapse} />
                  <Menu
                    selectedKeys={[selectedKey]}
                    items={menuItems}
                    onClick={onItemSelect}
                  />
                </div>
                <div className="sidebar-bottom">
                  <div className={menuCondition ? "user-compact" : "user-expand"}>
                    <div className="name-in-circle">
                      {nameFirstDigits(user?.name)}
                    </div>
                    {!menuCondition ? <h5 className="full-name">{user?.name}</h5> : ""}
                  </div>
                </div>
              </div>
            </ClickAwayListener>
          )}
        </Sider>
      </Layout>
      <ConfirmationModal
        visible={visible}
        setVisible={setVisible}
        onCancel={handleModalVisibility}
        onConfirm={logOut}
      >
        Are you sure you want to sign out?
      </ConfirmationModal>
    </>
  );
};

export default FleetLayout;
