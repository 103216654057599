import { TagTypes } from "../TagTypes";

export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      inviteUserApi: build.mutation({
        query: ({ driver_profile_id }) => ({
          data: {
            driver_profile_id,
          },
          url: "invite/driver",
          method: "POST",
        }),
      }),

      inviteFleetAdmin: build.mutation({
        query: (data) => ({
          data,
          url: "invite/fleet-admin",
          method: "POST",
        }),
      }),

      acceptUser: build.mutation({
        invalidatesTags: [TagTypes.ME],
        queryFn: async ({ decodedUrl, body }) => {
          const res = await fetch(
            new Request(decodedUrl, {
              body: body,
              method: "POST",
              redirect: "manual",
            })
          );

          return res;
        },
      }),

      inviteDriverBulk: build.mutation({
        query: ({ ids }) => ({
          method: "POST",
          params: {
            ids,
          },
          url: "invite/driver-bulk",
        }),
      }),
    }),
  });
}
