import { useSelectedFleet } from "hooks/useSelectedFleet";
import { useHasDocumentTypeWithDueToWhichLacksApprovedDocument } from "hooks/useHasDocumentTypeWithDueToWhichLacksApprovedDocument";

import { getDocumentStatusByDate, DocumentStatus } from "pages/documents";

import { NavLinkInternal } from "../NavLinkInternal";

export function NavLinkDocuments(props) {
  const documentsStatus = useDocumentsBadgeStatus();

  return (
    <NavLinkInternal
      {...props}
      status={documentsStatus}
      badge={documentsStatus}
    />
  );
}

export function useDocumentsBadgeStatus() {
  const { selectedFleetId, selectedDriverProfile } = useSelectedFleet();

  const { vehicleDocuments, driverDocuments } =
    useHasDocumentTypeWithDueToWhichLacksApprovedDocument();
  const vehicleOrDriverDocumentTypeWithDueToLacksApprovedDocument =
    vehicleDocuments || driverDocuments;

  return getDocumentMenuStatusByDriverProfile(
    selectedDriverProfile,
    selectedFleetId,
    vehicleOrDriverDocumentTypeWithDueToLacksApprovedDocument
  );
}

function getDocumentMenuStatusByDriverProfile(
  driverProfile,
  selectedFleetId,
  vehicleOrDriverDocumentTypeWithDueToLacksApprovedDocument
) {
  const {
    driver_vehicles: driverVehiclesOriginal = [],
    owner_vehicles: ownerVehiclesOriginal = [],
    driver_licence_expiry: driverLicenseExpiry,
    badge_expiry: badgeExpiry,
  } = driverProfile;

  /**
   * JUSTIFICATION FOR VEHICLES FILTERING
   *
   * `currentDriverProfile` may have vehicles that are not a part of current fleet (`selectedFleetId`),
   * thus some operations with them (like creating a document) are not possible,
   * thus they should not be counted in status calculation.
   *
   * Hence, Viktor Osipov and Ihor Kunytskiy agreed on filtering vehicles on the frontend
   * as a faster way to achieve wished result.
   */
  const driverVehiclesFiltered = driverVehiclesOriginal.filter(
    (vehicle) => String(vehicle.fleet_id) === String(selectedFleetId)
  );
  const ownerVehiclesFiltered = ownerVehiclesOriginal.filter(
    (vehicle) => String(vehicle.fleet_id) === String(selectedFleetId)
  );

  const documentsDates = [...driverVehiclesFiltered, ...ownerVehiclesFiltered]
    .flatMap((vehicle) => [vehicle?.insurance_expiry, vehicle?.plate_expiry])
    .concat([driverLicenseExpiry, badgeExpiry])
    .filter(Boolean);

  const statusByDates = getDocumentsMenuStatusByDates(documentsDates);

  if (statusByDates) {
    return statusByDates;
  }

  if (vehicleOrDriverDocumentTypeWithDueToLacksApprovedDocument) {
    return "warning";
  }
}

function getDocumentsMenuStatusByDates(dates) {
  const errorDate = dates.find(
    (date) => getDocumentStatusByDate(date) === DocumentStatus.EXPIRED
  );
  if (errorDate) return "error";

  const warningDate = dates.find(
    (date) => getDocumentStatusByDate(date) === DocumentStatus.EXPIRING
  );
  if (warningDate) return "warning";
}
