import { useDispatch } from "react-redux";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { initialized } from "store/slices/launchDarkly";

import { useEffectOnce } from "./useEffectOnce";

const INITIALIZATION_TIMEOUT_IN_SECONDS = 5;

export function useSynchronizeLaunchDarklyInitializationStatus() {
  const ldClient = useLDClient();
  const dispatch = useDispatch();

  useEffectOnce(() => {
    if (!ldClient) {
      return;
    }

    ldClient.waitForInitialization(INITIALIZATION_TIMEOUT_IN_SECONDS).then(
      () => {
        dispatch(initialized());
      },
      () => {
        dispatch(initialized());
      }
    );

  }, [ldClient, dispatch]);
}
