import { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./Select.module.scss";

/**
 * This component knows what to provide to the render-props,
 * thus it can be used in both controlled and uncontrolled ways.
 */
export const Select = forwardRef(function SelectInternal(
  {
    renderIcon,
    options = [],
    value: externalValue,
    defaultValue: externalDefaultValue,
    onChange: externalOnChange,
    ...otherProps
  },
  ref
) {
  const isControlled = typeof externalOnChange === "function";

  const [value, setValue] = useState(function getInitialValue() {
    if (isControlled) {
      return externalValue;
    }

    if (externalDefaultValue !== undefined) {
      return externalDefaultValue;
    }

    // Mimic native HTML <select>: select the first option by default
    return options[0]?.value;
  });

  useEffect(() => {
    if (isControlled) {
      setValue(externalValue);
    }
  }, [externalValue, isControlled]);

  function onChange(event) {
    setValue(event.target.value);

    if (isControlled) {
      externalOnChange(event);
    }
  }

  const hasIcon = typeof renderIcon === "function";

  return (
    <div className={styles.wrapper}>
      {hasIcon ? (
        <div className={styles.icon}>{renderIcon({ value })}</div>
      ) : null}

      <select
        className={clsx(styles.select, { [styles.hasIcon]: hasIcon })}
        {...otherProps}
        ref={ref}
        value={value}
        onChange={onChange}
      >
        {options.map((optionProps) => (
          <option key={optionProps.value} {...optionProps} />
        ))}
      </select>
    </div>
  );
});
