import { ReactComponent as CheckmarkIcon } from "assets/icons/filledCheckmark.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warningIcon.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/errorIcon.svg";

import { DocumentStatus } from "../../statusUtils";

const statusToIconComponent = {
  [DocumentStatus.UP_TO_DATE]: CheckmarkIcon,
  [DocumentStatus.IN_REVIEW]: CheckmarkIcon,
  [DocumentStatus.ABSENT]: CheckmarkIcon,
  [DocumentStatus.REQUIRES_INPUT]: WarningIcon,
  [DocumentStatus.EXPIRING]: WarningIcon,
  [DocumentStatus.EXPIRED]: ErrorIcon,
  [DocumentStatus.REJECTED]: ErrorIcon,
};

/**
 *
 * @param {"up-to-date" | "requires-input" | "expiring" | "expired"} status of
 *   the document
 */
export function DocumentStatusIcon({ status }) {
  const Icon = statusToIconComponent[status];

  if (Icon === undefined) {
    throw new Error(
      `Icon is not found. Looked for the icon which corresponds to the status="${status}"`
    );
  }

  return <Icon />;
}
