import { TagTypes } from "../TagTypes";
import { getNormalizedCurrentCompanyIdFromLocalStorage } from "../../CurrentCompanyIdContext";
import { changeLanguageDebounced } from "../../i18n";

export const paths = {
  me: "users/me",
};

export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      getMe: build.query({
        providesTags: [TagTypes.ME, TagTypes.DRAWDOWN_RELATED],
        query: () => ({
          url: paths.me,
          params: {
            include: ["lastPayout"],
          },
        }),
        async onQueryStarted(_, { queryFulfilled }) {
          try {
            const data = await queryFulfilled;
            const driverProfiles = data?.data?.data?.driver_profiles;

            const currentFleetId =
              getNormalizedCurrentCompanyIdFromLocalStorage();

            const currentDriverProfile = driverProfiles.find(
              (profile) => profile.fleet.id === parseInt(currentFleetId)
            );

            changeLanguageDebounced(currentDriverProfile?.fleet?.locale);
          } catch (error) {}
        },
      }),

      inviteUser: build.mutation({
        query: (data) => ({
          data,
          url: "users",
          method: "POST",
        }),
      }),

      signupUser: build.mutation({
        query: (data) => ({
          data,
          url: "users/signup",
          method: "POST",
        }),
      }),

      inviteUserWithEmail: build.mutation({
        query: (data) => ({
          data,
          url: "users/invite-email",
          method: "POST",
        }),
      }),

      getUsersBySearchTerm: build.mutation({
        query: ({ term }) => ({
          params: {
            term,
          },
          url: "users",
          method: "GET",
        }),
      }),

      createAccount: build.mutation({
        query: ({ code, password, password_confirmation, email, name }) => ({
          method: "PATCH",
          url: `users/code/${code}`,
          data: {
            password,
            password_confirmation,
            ...(name && {
              name,
            }),
          },
        }),
      }),

      updateUser: build.mutation({
        query: ({ id, body }) => ({
          data: body,
          url: `users/${id}`,
          method: "PATCH",
        }),
      }),

      forgetPassword: build.mutation({
        query: (data) => ({
          data,
          url: "users/forgot-password",
          method: "POST",
        }),
      }),

      updatePassword: build.mutation({
        query: ({ token, body }) => ({
          method: "PATCH",
          url: `users/token/${token}`,
          data: body,
        }),
      }),

      verifyUserEmail: build.mutation({
        query: (data) => ({
          method: "POST",
          url: `users/verification-email`,
          data,
        }),
      }),
    }),
  });
}

const emptyArray = [];
export function selectDriverProfilesFromGetMeResult(result) {
  const driverProfiles = result?.data?.data?.driver_profiles;

  if (!Array.isArray(driverProfiles)) {
    return emptyArray;
  }

  return driverProfiles;
}
