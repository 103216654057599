import { Checkbox } from 'antd';
import clsx from 'clsx';
import React from 'react';
import styles from './MobileTableItem.module.scss';

export const MobileTableItem = ({
  onSelect,
  actions,
  fields,
  isSelected,
  selectLabelComponent
}) => {
  const showHeader = onSelect || actions;

  return (
    <div className={styles.tableItem} >
      {showHeader && (
        <div className={styles.tableHeader} >
          <div className={styles.checkboxWrapper} >
            {onSelect && (
              <Checkbox
                checked={isSelected}
                onChange={onSelect}
              />
            )}
            {selectLabelComponent}
          </div>
          <div>
            {actions}
          </div>
        </div>
      )}
      {fields.map((field, index) => (
        <div key={index} className={styles.tableField} >
          <p>{field.label}</p>
          <span className={clsx(field.bold && styles.bold, field.contentClass)} >{field.render}</span>
        </div>
      ))}
    </div>
  )
}
