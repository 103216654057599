import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { message } from "antd";

import { api } from "api";
import { routes } from "routes";
import { useFeatureFlagsFromSearchParams } from "hooks/useFeatureFlagsFromSearchParams";

import { WaveLayout } from "components/WaveLayout";
import { Title } from "components/Title";
import { Container } from "components/Container";
import { Description } from "components/Description";

import { CompleteRegistrationForm } from "./CompleteRegistrationForm";

export function CompleteRegistrationPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const name = searchParams.get("name") ?? "";
  const email = searchParams.get("email") ?? "";
  const url = searchParams.get("url") ?? "";

  const {
    releaseRequireTermsAndConditionsOnJoining:
      releaseRequireTermsAndConditionsOnJoiningFromLaunchDarkly,
  } = useFlags();
  const {
    isReleaseRequireTermsAndConditionsOnJoiningEnabled:
      releaseRequireTermsAndConditionsOnJoiningFromSearchParams,
  } = useFeatureFlagsFromSearchParams();

  const requireTermsAndConditions =
    releaseRequireTermsAndConditionsOnJoiningFromLaunchDarkly ||
    releaseRequireTermsAndConditionsOnJoiningFromSearchParams;

  const [logIn] = api.endpoints.login.useMutation();
  const [acceptUser] = api.endpoints.acceptUser.useMutation();

  const [acceptLatestUserAgreementVersion] =
    api.endpoints.acceptLatestUserAgreementVersion.useMutation();

  const navigate = useNavigate();

  async function doAcceptUser(decodedUrl, formData) {
    try {
      await acceptUser({
        decodedUrl,
        body: formData,
      }).unwrap();

      return true;
    } catch (err) {
      console.log(err);
      message.error({
        content: t("error.somethingWentWrong"),
        key: "complete",
      });

      return false;
    }
  }

  async function doLogIn(password) {
    try {
      return await logIn({
        email,
        password,
      }).unwrap();
    } catch (error) {
      message.error({
        content: error?.data?.message ?? t("error.somethingWentWrong"),
        key: "complete",
      });

      return null;
    }
  }

  async function onSubmit({ password, name }) {
    message.loading({
      content: t("processing.signingIn"),
      key: "complete",
    });

    const decodedUrl = atob(url);

    const acceptUserSuccess = await doAcceptUser(
      decodedUrl,
      createFormData({ password, name, email })
    );

    if (!acceptUserSuccess) {
      return;
    }

    const loginRes = await doLogIn(password);

    if (!loginRes) {
      return;
    }

    if (requireTermsAndConditions) {
      acceptLatestUserAgreementVersion();
    }

    storeUserAuthToken(loginRes.meta.token);

    message.success({
      content: t("success.signedIn"),
      key: "complete",
    });
    navigate(routes.index.getURL(), { replace: true });
  }

  const initialValues = useMemo(() => {
    return {
      name: name,
      password: "",
      passwordConfirm: "",
      acceptAgreement: false,
    };
  }, [name]);

  return (
    <WaveLayout>
      <Container width="small">
        <Title>{t("pageCompleteRegistration.welcome")}</Title>
        <Description>
          <p>{t("pageCompleteRegistration.completeDetails")}</p>
        </Description>
        <CompleteRegistrationForm
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      </Container>
    </WaveLayout>
  );
}

function createFormData(object) {
  return Object.entries(object).reduce((formData, [key, value]) => {
    formData.set(key, value);
    return formData;
  }, new FormData());
}

const storeUserAuthToken = (token) => {
  localStorage.setItem("auth_token", JSON.stringify(token));
};
