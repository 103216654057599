import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { InputNumber } from "antd";
import debounce from "lodash/debounce";
import { useTranslation, Trans } from "react-i18next";

import { twoDecimals } from "helpers/twoDecimal";
import { showErrorMsg, showSuccessMsg } from "helpers/windowMassages";
import { formatCurrency } from "helpers/formatCurrency";

import { api } from "api";

import { useSelectedFleet } from "hooks/useSelectedFleet";

import { Button } from "components/Button";
import { ReactComponent as LoadingIcon } from "assets/icons/loader.svg";

import { NextPayoutWidget } from "./NextPayoutWidget";

export function WalletBalanceForm({
  driverProfile,
  setSchedulePayoutsRefresh,
}) {
  const { t } = useTranslation();

  const [fastPayAmount, setFastPayAmount] = useState(0);
  const [drawdownAmount] = api.endpoints.drawdownAmount.useMutation();
  const driverProfileId = driverProfile?.id;
  const { selectedFleetCurrency, selectedFleet } = useSelectedFleet();

  const [
    calculatePayoutFees,
    { isLoading: isCalculationLoading, data: feeCalculationData },
  ] = api.endpoints.calculatePayoutFees.useMutation();

  const { data, isLoading: isInstantBalanceQueryLoading } =
    api.endpoints.getDriverInstantAvailableBalance.useQuery(
      {
        driverId: driverProfileId,
        currency: selectedFleet?.currency,
      },
      { skip: !driverProfileId }
    );

  const calculateWithDebounce = useRef(
    debounce((amount, id) => {
      calculatePayoutFees({
        id,
        amount: amount * 100,
      });
    }, 500)
  ).current;

  const normalizedFeeCalculationData = useMemo(() => {
    if (!fastPayAmount) {
      return null;
    }
    try {
      const parsedJSON = JSON.parse(feeCalculationData);
      return parsedJSON;
    } catch {
      return null;
    }
  }, [fastPayAmount, feeCalculationData]);

  const instantAvailableBalance = (data?.instant_available || 0) / 100;

  const onValueHandle = useCallback(
    (val) => {
      let value = val || 0;
      if (value > instantAvailableBalance) {
        value = instantAvailableBalance;
      }
      setFastPayAmount(value);
      if (!instantAvailableBalance || !value) {
        return;
      }
      calculateWithDebounce(value, driverProfileId);
    },
    [calculateWithDebounce, driverProfileId, instantAvailableBalance]
  );

  const onSubmit = () => {
    drawdownAmount({
      id: driverProfile?.id,
      amount: fastPayAmount * 100,
    })
      .unwrap()
      .then((res) => {
        console.log(res);
        setFastPayAmount(0);
        showSuccessMsg({ content: t("success.success") });
      })
      .catch((err) => {
        showErrorMsg({ content: t("error.somethingWentWrong") });
      })
      .finally(() => {
        setSchedulePayoutsRefresh(true);
      });
  };

  const onReset = () => {
    setFastPayAmount(0);
  };

  useEffect(() => {
    return () => {
      calculateWithDebounce.cancel();
    };
  }, [calculateWithDebounce]);

  const calculatedFee = useMemo(() => {
    const fee = normalizedFeeCalculationData?.amount / 100;

    if (isNaN(fee)) {
      return 0;
    }
    return fee;
  }, [normalizedFeeCalculationData?.amount]);

  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flex: "1 1 250px" }}>
          <h1 className="payout-header">{t("pageHome.walletBalance")}</h1>
          <div className="balance-amount">
            <h2 className="amount-text">
              {formatCurrency(
                (driverProfile?.stripe_balance_instant_available ?? 0) / 100,
                selectedFleet?.currency
              )}
            </h2>

            <div
              style={{
                color: "#eb7100",
                fontWeight: 500,
                marginTop: 8,
                paddingTop: 4,
              }}
            >
              {selectedFleet?.payout_frequency === "weekly" ? (
                <NextPayoutWidget />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="fast-pay-amt">
        {isInstantBalanceQueryLoading && <LoadingIcon />}
        <h3>
          {t("pageHome.instantPayoutUpToN", {
            maximumPayout:
              selectedFleetCurrency + twoDecimals(instantAvailableBalance),
          })}
        </h3>
      </div>
      <InputNumber
        addonBefore={selectedFleetCurrency}
        value={fastPayAmount}
        min={0}
        max={(instantAvailableBalance || 40) * 100}
        onChange={onValueHandle}
      />
      <p className="drawdown-text">
        {isCalculationLoading && <LoadingIcon />}
        <Trans
          i18nKey="pageHome.drawingDownAmountIncurFee"
          values={{
            amount: selectedFleetCurrency + (twoDecimals(fastPayAmount) || ""),
            fee: selectedFleetCurrency + twoDecimals(calculatedFee),
          }}
          components={{
            amount: <b />,
            fee: <b />,
          }}
        />
      </p>

      <div style={{ display: "flex", columnGap: "16px" }}>
        <Button
          uppercase
          nowrap
          variant="primary"
          shape="rounded"
          disabled={!fastPayAmount}
          onClick={onSubmit}
        >
          {t("action.drawdownNow")}
        </Button>
        <Button uppercase shape="rounded" onClick={onReset}>
          {t("action.reset")}
        </Button>
      </div>
    </>
  );
}
