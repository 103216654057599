import clsx from "clsx";
import { twoDecimals } from "helpers/twoDecimal";
import React from "react";
import "./PayoutComp.scss";

export const PayoutComp = ({ date, ammount, show }) => {
  return (
    <div className={clsx("payout-wrapper", !show && 'disabled')}>
      <span>{show ? date : 'DD/MM/YYYY'}</span>
      <span>{show ? ammount : twoDecimals(0)}</span>
    </div>
  );
};
