import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import { StateHandler } from "components/LoadingComp";
import { SubTopNavigator } from "components/SubTopNavigator";
import { Button } from "components/Button";

import { useSelectedFleet } from "hooks/useSelectedFleet";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { api } from "api";
import { DRIVER_PROFILE_ID_QUERY_PARAM } from "pages/BookingsListPage";
import "./Booking.scss";
import { LeftDetails } from "./LeftDetails";
import { RightDetails } from "./RightDetails";

export const BookingItemInternal = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { bookingId } = useParams();
  const [params] = useSearchParams();
  const driverProfileId = params.get(DRIVER_PROFILE_ID_QUERY_PARAM);
  const { selectedFleetId, selectedFleetCurrency } = useSelectedFleet();

  const { data: driverData, isLoading: isDriverQueryLoading } =
    api.endpoints.getDriver.useQuery(selectedFleetId, {
      skip: !selectedFleetId,
    });

  const currentDriver = driverData?.data;

  const {
    data: bookingData,
    isLoading,
    isFetching,
  } = api.endpoints.getBookingById.useQuery(
    {
      id: bookingId,
      params: {
        [DRIVER_PROFILE_ID_QUERY_PARAM]: driverProfileId || currentDriver?.id,
      },
    },
    {
      skip: !bookingId,
    }
  );

  const loading = isLoading || isFetching || isDriverQueryLoading;

  const changeBooking = (id) => {
    const queryParams = new URLSearchParams();
    queryParams.append(
      DRIVER_PROFILE_ID_QUERY_PARAM,
      driverProfileId || currentDriver?.id
    );
    navigate(`/wallet/wallet_bookings/${id}?${queryParams.toString()}`);
  };

  const { data = {}, meta = {} } = bookingData || {};

  return (
    <div className="driver-booking-wrapper">
      <StateHandler
        companySelected={false}
        showLoader={loading}
        showEmpty={!bookingData}
        emptyTitle="bookings"
      >
        <SubTopNavigator
          pathName="wallet/wallet_bookings"
          subject={t("pageBooking.bookingId", { id: data.booking_id })}
        />
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} xl={16}>
            <LeftDetails {...{ data }} />
          </Col>
          <Col xs={24} sm={24} md={24} xl={7}>
            <RightDetails data={data} currency={selectedFleetCurrency} />
          </Col>
        </Row>
        <div className="bottom-section">
          <Button
            variant="primary"
            shape="rounded"
            disabled={!meta?.previous_id}
            onClick={() => changeBooking(meta?.previous_id)}
          >
            {t("action.previous")}
          </Button>
          <Button
            variant="primary"
            shape="rounded"
            disabled={!meta?.next_id}
            onClick={() => changeBooking(meta?.next_id)}
          >
            {t("action.next")}
          </Button>
        </div>
      </StateHandler>
    </div>
  );
};
