import { Checkbox, Tooltip, message } from "antd";
import clsx from "clsx";
import { InfiniteScrollComp } from "components/InfiniteScroll";
import { StateHandler } from "components/LoadingComp";
import { MobileTableItem } from "components/MobileTableItem";
import { format } from "date-fns";
import { twoDecimals } from "helpers/twoDecimal";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "helpers/windowMassages";
import { useIsMobile } from "hooks/useMobile";
import { useQueryCompanies } from "hooks/useQueryCompanies";
import { useWindowSize } from "hooks/useWindowSize";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "api";
import { CustomDropdown } from "components/CustomDropdown";
import { driverRecordActionOptions } from "../Filters/DropdownOptions";
import { driversHeaderItems } from "./constants";
import { InviteDriverIcon } from "./InviteDriverIcon";
import { PayoutComp } from "./PayoutComp/PayoutComp";
import useRoles from "hooks/useRoles";
import { RemoveDriverModal } from "./RemoveDriverModal/RemoveDriverModal";

export const DRIVERS_LIST_TABLE_WRAPPER = "driver-list-table-section-wrapper";

export const Table = ({
  data: drivers,
  loading,
  id,
  getNextDrivers,
  currentPage,
  lastPage,
  onRowClick,
  selectedDrivers,
  selectDriver,
  headerSection,
  topScrollId,
  isUninitialized,
  setDriverInvited,
  onScroll,
  queryLoading,
  refetch
}) => {
  let navigate = useNavigate();
  const [inviteUser] = api.endpoints.inviteUserApi.useMutation();
  const { currentCompanyCurrency: selectedCompanyCurrency } = useQueryCompanies();

  const { isSuperAdmin, isFleet } = useRoles();

  const canRemoveDriver = isFleet || isSuperAdmin;
  const [changeDriverStatus] = api.endpoints.changeDriverStatus.useMutation();
  const [selectedDriver, setSelectedDriver] = useState(null);

  const onInvite = useCallback(
    (driverId) => {
      showLoadingMsg({ content: "Sending Invite!" });
      inviteUser({
        driver_profile_id: driverId,
      })
        .unwrap()
        .then(() => {
          setDriverInvited(driverId);
          showSuccessMsg({
            content:
              "A link has been sent to this driver email for further registration",
          });
          refetch();
        })
        .catch(({ data }) => {
          showErrorMsg({ content: data.error.message });
        });
    },
    [inviteUser, refetch, setDriverInvited]
  );

  const handleInviteNonActiveUser = (driverId) => {
    changeDriverStatus({
      driverId,
      is_active: true
    })
      .unwrap()
      .then(() => {
        return onInvite(driverId);
      })
      .catch(() => {
        showErrorMsg({ content: "Something went wrong while removing this driver" });
      })
  }

  const isMobile = useIsMobile();
  const [wrapperComponent, setWrapperComponent] = useState(null);
  useWindowSize();

  const onWrapperMount = useCallback((node) => {
    if (node) {
      setWrapperComponent(node);
    }
  }, []);

  const wrapperHeight = wrapperComponent?.clientHeight;

  const onRowSelect = (e, driver) => {
    selectDriver(driver);
  };

  const onItemSelect = (option, id, is_active) => {
    switch (option) {
      case "viewDetail":
        navigate(`${id}`);
        break;
      case "inviteUser":
        {
          if (!is_active) {
            handleInviteNonActiveUser(id);
            break;
          } else {
            onInvite(id);
            break;
          }
        }
      case "removeDriver":
        setSelectedDriver(drivers.find((driver) => driver.id === id));
        break;
      default:
        break;
    }
  };

  const handleDriverRemoval = () => {
    const loadingMsgKey = 'driver_removal';
    showLoadingMsg({ content: 'Removing driver!', key: loadingMsgKey });
    changeDriverStatus({
      driverId: selectedDriver.id,
      is_active: false
    })
      .unwrap()
      .then(() => {
        refetch();
        showSuccessMsg({
          content: `Succesfully removed driver with id ${id}`
        });
      })
      .catch(() => {
        showErrorMsg({ content: "Something went wrong while removing this driver" });
      })
      .finally(() => {
        message.destroy(loadingMsgKey);
        setSelectedDriver(null);
      });
  }

  return (
    <div ref={onWrapperMount} className="drivers-table-wrapper">
      <div className="drivers-table-container">
        <StateHandler
          companySelected={!id}
          showLoader={false}
          showEmpty={!drivers?.length && !isUninitialized && !queryLoading}
          emptyTitle="drivers"
          headerComponent={headerSection}
        >
          <InfiniteScrollComp
            dataLength={drivers?.length}
            next={getNextDrivers}
            height={`${wrapperHeight}px`}
            hasMore={currentPage < lastPage}
            topic="drivers"
            showEndMessage={!loading}
            onScroll={onScroll}
          >
            <div className="driver-list-header-section-wrapper">
              {headerSection}
            </div>
            <div className="driver-list-table-wrapper">
              <div className={DRIVERS_LIST_TABLE_WRAPPER}>
                <span id={topScrollId} />
                {!isMobile && (
                  <div className="header">
                    {driversHeaderItems.map((item, index) => {
                      return (
                        <Tooltip
                          title={`${item === "trips"
                            ? "Total number of trips for the last month"
                            : ""
                            }`}
                          key={index}
                        >
                          <div key={index}>{item.toUpperCase()}</div>
                        </Tooltip>
                      );
                    })}
                  </div>
                )}
                {isMobile ? (
                  <>
                    {drivers?.map((driver) => {
                      const {
                        id,
                        ref,
                        name,
                        trips,
                        signed_up_at,
                        invited_at,
                        stripe_payouts_enabled,
                        stripe_details_submitted,
                        last_payout,
                        is_active
                      } = driver;
                      return (
                        <div key={id} className="mobile-row">
                          <MobileTableItem
                            fields={[
                              {
                                label: "ID",
                                render: id,
                              },
                              {
                                label: "Driver ID",
                                render: ref,
                              },
                              {
                                label: "Name",
                                render: name.toLowerCase(),
                                contentClass: "capitalize",
                              },
                              {
                                label: "Trips",
                                render: (
                                  <div
                                    onClick={() => onRowClick(ref, trips)}
                                    className={clsx(
                                      "trip",
                                      trips === 0 && "no-trip"
                                    )}
                                  >
                                    {trips}
                                  </div>
                                ),
                              },
                              {
                                label: "Last Payout",
                                render: (
                                  <PayoutComp
                                    date={last_payout?.arrival_date.replace(
                                      "-",
                                      "/"
                                    )}
                                    ammount={`${selectedCompanyCurrency}${twoDecimals(
                                      last_payout?.amount / 100
                                    )}`}
                                    show={last_payout}
                                  />
                                ),
                                contentClass: "payout-component-wrapper",
                              },
                              {
                                label: "Invitation Status",
                                render: (
                                  <InviteDriverIcon
                                    invited_at={invited_at}
                                    signed_up_at={signed_up_at}
                                    stripe_details_submitted={
                                      stripe_details_submitted
                                    }
                                    stripe_payouts_enabled={
                                      stripe_payouts_enabled
                                    }
                                    is_active={is_active}
                                  />
                                ),
                              },
                            ]}
                            onSelect={(e) => onRowSelect(e, driver)}
                            isSelected={selectedDrivers[driver.id]}
                            actions={
                              <CustomDropdown
                                options={driverRecordActionOptions(
                                  !signed_up_at || !is_active,
                                  canRemoveDriver,
                                  is_active
                                )}
                                onItemSelect={(e) => onItemSelect(e, id, is_active)}
                              />
                            }
                          />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {drivers?.map((driver, index) => {
                      const {
                        id,
                        ref,
                        name,
                        trips,
                        signed_up_at,
                        invited_at,
                        stripe_payouts_enabled,
                        stripe_details_submitted,
                        last_payout,
                        is_active
                      } = driver;
                      const isRowSelected = selectedDrivers[driver.id];
                      return (
                        <div
                          className={clsx("row", isRowSelected && "selected")}
                          key={index}
                        >
                          <div className="cell">
                            <Checkbox
                              onChange={(e) => onRowSelect(e, driver)}
                              checked={isRowSelected}
                            />
                            {ref}
                          </div>
                          <div className="cell capitalize">
                            {name.toLowerCase()}
                          </div>
                          <div
                            onClick={() => onRowClick(ref, trips)}
                            className={clsx(
                              "trip cell",
                              trips === 0 && "no-trip"
                            )}
                          >
                            <span>{trips}</span>
                          </div>
                          <div className="cell">
                            {/* TODO: Will make it red color if the ammount is negative in both
                last and nextPayout */}
                            <PayoutComp
                              date={
                                last_payout?.arrival_date
                                  ? format(
                                    new Date(last_payout?.arrival_date),
                                    "dd/MM/yyyy"
                                  )
                                  : "DD/MM/YYYY"
                              }
                              ammount={`${selectedCompanyCurrency}${twoDecimals(
                                last_payout?.amount / 100
                              )}`}
                              show={last_payout}
                            />
                          </div>
                          <div className="cell">
                            <InviteDriverIcon
                              invited_at={invited_at}
                              signed_up_at={signed_up_at}
                              stripe_details_submitted={
                                stripe_details_submitted
                              }
                              stripe_payouts_enabled={stripe_payouts_enabled}
                              is_active={is_active}
                            />
                          </div>
                          <div className="cell">
                            <CustomDropdown
                              options={driverRecordActionOptions(
                                !signed_up_at || !is_active,
                                canRemoveDriver,
                                is_active
                              )}
                              onItemSelect={(e) => onItemSelect(e, id, is_active)}
                              className={`driver_${id}_options`}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </InfiniteScrollComp>
        </StateHandler>
      </div>
      <RemoveDriverModal
        selectedDriver={selectedDriver}
        onClose={() => setSelectedDriver(null)}
        onConfirm={handleDriverRemoval}
      />
    </div>
  );
};

export default Table;
