import { Grid } from 'antd';
const { useBreakpoint } = Grid;

export const useIsMobile = () => {
  const screens = useBreakpoint();

  const stringifiedBreakpoint = Object.keys(screens).filter((key) => screens[key]).join('-');
  const isMobile = stringifiedBreakpoint === 'xs' || stringifiedBreakpoint === 'xs-sm';

  return isMobile;
}
