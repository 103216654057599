import { Button, Form, Switch } from "antd";
import { StateHandler } from "components/LoadingComp";
import { useEffect } from "react";
import { api } from "api";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../helpers/windowMassages";
import { RedirectToCompany, useQueryCompanies } from "hooks/useQueryCompanies";

export const Settings = () => {
  const { currentCompany: company } = useQueryCompanies({
    redirectTo: RedirectToCompany.ADMIN,
  });
  const [modifySubscription] = api.endpoints.companyIcabbyModifySubscription.useMutation();
  const [modifySettings] = api.endpoints.modifySettings.useMutation();
  const { data, isLoading, isFetching } = api.endpoints.getFleetById.useQuery({ id: company?.id }, {
    skip: !company?.id
  });

  const fleetData = data?.data ?? {};
  const hasData = Object.keys(fleetData).length > 0;

  const {
    subscribe_webhook,
    enable_driver_pay,
    id,
    automatic_user_invitation,
    enable_documents_feature,
  } = fleetData;

  const [form] = Form.useForm();

  const onFinish = (values) => {
    showLoadingMsg({ content: "Subscribing..." });
    const {
      sub_webhook = false,
      enable_driver_pay,
      automatic_user_invitation,
      enable_documents_feature = true,
    } = values;

    if (sub_webhook !== subscribe_webhook) {
      modifySubscription({
        id,
        action: sub_webhook ? "subscribe" : "unsubscribe"
      })
        .unwrap()
        .then(() => {
          showSuccessMsg({
            content: `${sub_webhook ? "Subscribed" : "Unsubscribed"
              }  successfully`,
          });
        })
        .catch(() => {
          showErrorMsg({
            content: `Something went wrong when ${sub_webhook ? "subscribing" : "unsubscribing"
              } `,
          });
        });
    }

    modifySettings({
      fleetId: id,
      data: {
        enable_driver_pay,
        automatic_user_invitation,
        enable_documents_feature,
      }
    })
      .unwrap()
      .then(({ data }) => {
        form.setFieldsValue({
          enable_driver_pay: data?.enable_driver_pay,
          sub_webhook: data?.subscribe_webhook,
          automatic_user_invitation: data?.automatic_user_invitation,
          enable_documents_feature: data?.enable_documents_feature,
        });
        showSuccessMsg({
          content: 'Successfully modified settings'
        });
      })
      .catch(() => {
        showErrorMsg({
          content: 'Something went wrong while updating settings!',
        });
      });
  };

  useEffect(() => {
    //For updating initial status when first render
    form.setFieldsValue({
      enable_driver_pay: Boolean(enable_driver_pay),
      sub_webhook: subscribe_webhook,
      automatic_user_invitation: automatic_user_invitation,
      enable_documents_feature: Boolean(enable_documents_feature),
    });
  }, [automatic_user_invitation, enable_driver_pay, form, subscribe_webhook, enable_documents_feature]);

  const showLoader = isLoading || isFetching;

  return (
    <StateHandler showLoader={showLoader} companySelected={!id && !showLoader} showEmpty={!hasData} emptyTitle="data">
      <Form className="fleet-admin-settings" onFinish={onFinish} form={form}>
        <Form.Item
          label="Enable Driver Pay"
          name="enable_driver_pay"
          valuePropName="checked"
        >
          <Switch loading={!hasData} />
        </Form.Item>

        <Form.Item
          label="Sync Bookings & Drivers"
          name="sub_webhook"
          valuePropName="checked"
        >
          <Switch loading={!hasData} />
        </Form.Item>

        <Form.Item
          label="Auto driver invitation"
          name="automatic_user_invitation"
          valuePropName="checked"
        >
          <Switch loading={!hasData} />
        </Form.Item>

        <Form.Item
          label="Enable documents"
          name="enable_documents_feature"
          valuePropName="checked"
        >
          <Switch loading={!hasData} />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit">save</Button>
        </Form.Item>
      </Form>
    </StateHandler>
  );
};

export default Settings;
