import React, { Fragment } from 'react';
import styles from './NotificationGroup.module.scss';
import { NotificationTicket } from './NotificationTicket/NotificationTicket';

export const NotificationGroup = ({
  notificationGroup
}) => {
  const notifications = notificationGroup[1];

  return (
    <div>
      <p className={styles.date} >{notificationGroup[0]}</p>
      <div className={styles.wrapper} >
        {notifications.map((notification, index) => (
          <Fragment key={notification.id}>
            <NotificationTicket
              created_at={notification.created_at}
              message={notification.message}
              title={notification.title}
              type={notification.type}
            />
            {index !== notifications.length - 1 && (
              <div className={styles.divider} />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  )
}
