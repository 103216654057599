import Vapor from "laravel-vapor";
import { getReactAppBaseUrl } from "mocks/env";

export function storeOnVapor(
  file,
  { token = JSON.parse(localStorage.getItem("auth_token")) } = {}
) {
  if (!token) {
    return Promise.reject("Token is needed to store a file on vapor");
  }

  return Vapor.store(file, {
    baseURL: (() => {
      const url = new URL(getReactAppBaseUrl());
      return url.origin;
    })(),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
