import { forwardRef } from "react";
import clsx from "clsx";
import styles from "./Input.module.scss";

export const Input = forwardRef(function InputInternal(
  { invalid, ...otherProps },
  ref
) {
  return (
    <input
      {...otherProps}
      className={clsx(styles.input, {
        [styles.invalid]: invalid,
        [styles.readonly]: otherProps.readOnly,
      })}
      ref={ref}
    />
  );
});
