import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";


export const InfiniteScrollComp = ({
  dataLength,
  next,
  height = "calc(100vh - 287px)",
  hasMore,
  onScroll,
  topic,
  wrapperRef,
  children,
  className,
  showEndMessage = false,
  otherProps,
}) => {
  const { t } = useTranslation();

  return (
    <div ref={wrapperRef} className={className}>
      <InfiniteScroll
        dataLength={dataLength}
        next={next}
        height={height}
        hasMore={hasMore}
        onScroll={onScroll}
        endMessage={
          showEndMessage ? (
            <p style={{ textAlign: "center" }}>
              <b>{t("componentInfiniteScroll.noMoreTopicToSee", { topic })}</b>
            </p>
          ) : null
        }
        scrollThreshold={0.95}
        {...otherProps}
      >
        {children}
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollComp;
