import React from "react";
import { useTranslation } from "react-i18next";

import { WaveLayout } from "components/WaveLayout";
import { Container } from "components/Container";
import { Spacer } from "components/Spacer";
import { Title } from "components/Title";
import { Description } from "components/Description";
import { Button } from "components/Button";

import { ReactComponent as CompletedActionIcon } from "assets/icons/completedAction.svg";
import { ReactComponent as PoweredByStripeIcon } from "assets/icons/stripe-powered-icon.svg";

import { routes } from "routes";

export const StripeCompletedPage = () => {
  const { t } = useTranslation();

  return (
    <WaveLayout>
      <Container width="small">
        <div style={{ textAlign: "center" }}>
          <CompletedActionIcon />
        </div>
        <Spacer />
        <Title>{t("pageStripeCompleted.nearlyThere")}</Title>
        <Description>
          <p>{t("pageStripeCompleted.stripeWillConfirmYourIdentity")}</p>
          <p>
            <b>{t("pageStripeCompleted.startTrackingYourEarnings")}</b>
          </p>
        </Description>
        <Button
          block
          uppercase
          variant="primary"
          shape="rounded"
          to={routes.walletHome.getURL()}
        >
          {t("action.viewMyEarnings")}
        </Button>
        <Spacer />
        <PoweredByStripeIcon />
      </Container>
    </WaveLayout>
  );
};
