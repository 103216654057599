import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  getLatestTermsAndConditionsVersion,
  getTermsAndConditionsVersionList,
} from "i18n";

import { Container } from "components/Container";
import { WaveLayout } from "components/WaveLayout";

export function TermsAndConditionsPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const termsAndConditionsVersionFromSearchParams = searchParams.get("version");
  const termsAndConditionsVersion = getTermsAndConditionsVersionList().includes(
    termsAndConditionsVersionFromSearchParams
  )
    ? termsAndConditionsVersionFromSearchParams
    : getLatestTermsAndConditionsVersion();

  return (
    <WaveLayout>
      <Container>
        <div
          dangerouslySetInnerHTML={{
            __html: t(`termsAndConditions.${termsAndConditionsVersion}`),
          }}
        ></div>
      </Container>
    </WaveLayout>
  );
}
