import { Alert } from "antd";
import { LoadingComp } from "components/LoadingComp";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { api, axiosInstance } from "api";
import { Banner } from "components/Banner";
import { SubTopNavigator } from "components/SubTopNavigator";
import { twoDecimals } from "../../../helpers/twoDecimal";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../helpers/windowMassages";
import DeclinementDrawer from "../BookingsList/Filters/DeclinementDrawer/DeclinementDrawer";
import "./Booking.scss";

export const Booking = () => {
  const { bookingId } = useParams();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const {
    data: booking,
    isLoading,
    refetch,
  } = api.endpoints.getBookingById.useQuery({ id: bookingId });

  const bookingData = booking?.data;

  const handleDrawer = () => {
    setVisibleDrawer((prevState) => !prevState);
  };

  const onBookingApprove = () => {
    showLoadingMsg();
    axiosInstance.put(`bookings/${bookingData.id}/payout-status`, {
      payout_status: "approved",
      payout_status_reason: null,
    })
      .then((res) => {
        showSuccessMsg({ content: "Approved successfully" });
        refetch();
      })
      .catch((err) => {
        console.log(err);
        showErrorMsg({ content: "Something went wrong" });
      });
  };

  const onDeclinement = (reason) => {
    showLoadingMsg();
    axiosInstance.put(`bookings/${bookingData.id}/payout-status`, {
      payout_status: "declined",
      payout_status_reason: reason,
    })
      .then((res) => {
        showSuccessMsg({ content: "Declined successfully" });
        setVisibleDrawer(false);
        refetch();
      })
      .catch((err) => {
        console.log(err);
        showErrorMsg({ content: "Something went wrong" });
      });
  };

  return (
    <div className="booking-wrapper">
      <LoadingComp loading={isLoading}>
        <SubTopNavigator
          pathName="console/transactions"
          subject="Transaction"
        />
        <div className="details-wrapper" >
          <div className="details-section">
            <h1 className="bookingId" >Booking ID: {bookingData?.booking_id}</h1>
            <div className="row-details">
              <Banner
                headingLevel="h4"
                headingText="date"
                description={
                  bookingData?.date
                    ? format(
                      utcToZonedTime(new Date(bookingData?.date), "UTC"),
                      "dd/MM/yy"
                    )
                    : "-"
                }
              />
              <Banner
                headingLevel="h4"
                headingText="time"
                description={
                  bookingData?.date
                    ? format(
                      utcToZonedTime(new Date(bookingData?.date), "UTC"),
                      "hh:mm"
                    )
                    : "-"
                }
              />
            </div>
            <div className="row-details collapseMobile">
              <Banner
                headingLevel="h4"
                headingText="pickup"
                description={bookingData?.pick_up}
              />
              <Banner
                headingLevel="h4"
                headingText="destination"
                description={bookingData?.destination}
              />
            </div>
            <div className="row-details">
              <Banner
                headingLevel="h4"
                headingText="driver name"
                description={bookingData?.driver?.name}
              />
              <Banner
                headingLevel="h4"
                headingText="driver ref"
                description={bookingData?.driver?.ref}
              />
            </div>
            <div className="row-details">
              <Banner
                headingLevel="h4"
                headingText="payment type"
                description={bookingData?.payment_type}
              />
              <Banner
                headingLevel="h4"
                headingText="payment status"
                description={
                  <span className="payment-status-section">
                    <span>{bookingData?.payout_status?.toUpperCase()}</span>

                    {/* <div className="action-buttons">
                    {(booking?.payout_status === "approved" ||
                      booking?.payout_status === "pending") && (
                      <Button onClick={handleDrawer} className="decline-btn">
                        Withheld
                      </Button>
                    )}
                    {(booking?.payout_status === "declined" ||
                      booking?.payout_status === "pending") && (
                      <Button
                        className="approve-btn"
                        onClick={onBookingApprove}
                      >
                        Approve
                      </Button>
                    )}
                  </div> */}
                  </span>
                }
              />
            </div>
            <div className="payment-breakdown-section">
              <div>
                <h4>PAYMENT BREAKDOWN</h4>
                <h4>COST</h4>
                <h4>PRICE</h4>
              </div>

              <div>
                <h5>Fare Amount</h5>
                <h5>£{twoDecimals(bookingData?.cost / 100)}</h5>
                <h5>£{twoDecimals(bookingData?.payment_breakdown?.price)}</h5>
              </div>
              <div>
                <h5>Tip</h5>
                <h5>£{twoDecimals(bookingData?.payment_breakdown?.tip)}</h5>
                <h5>£{twoDecimals(bookingData?.payment_breakdown?.tip)}</h5>
              </div>
              <div>
                <h5>Tolls</h5>
                <h5>£{twoDecimals(bookingData?.payment_breakdown?.tolls)}</h5>
                <h5>£{twoDecimals(bookingData?.payment_breakdown?.tolls)}</h5>
              </div>
              <div>
                <h5>Extras</h5>
                <h5>£{twoDecimals(bookingData?.payment_breakdown?.extras)}</h5>
                <h5>£{twoDecimals(bookingData?.payment_breakdown?.extras)}</h5>
              </div>
              <div>
                <h5>Waiting time</h5>
                <h5>
                  £{twoDecimals(bookingData?.payment_breakdown?.waiting_cost)}
                </h5>
                <h5>
                  £{twoDecimals(bookingData?.payment_breakdown?.waiting_price)}
                </h5>
              </div>
              {/* // TODO: IF payment type invoice it will be shown */}
              {bookingData?.payment_type === "INVOICE" && (
                <div>
                  <h5>Service charge</h5>
                  <h5></h5>
                  <h5>
                    £{twoDecimals(bookingData?.payment_breakdown?.service_charge)}
                  </h5>
                </div>
              )}

              <div>
                <h5>Total due</h5>
                <h5>£{twoDecimals(bookingData?.total_cost / 100)}</h5>
                <h5>£{twoDecimals(bookingData?.payment_breakdown?.total)}</h5>
              </div>
            </div>
            {bookingData?.payout_status === "declined" && (
              <Alert
                message="Reason for withheld funds"
                description={bookingData?.payout_status_reason}
                type="error"
              />
            )}
          </div>
        </div>
        <DeclinementDrawer
          visible={visibleDrawer}
          onChange={handleDrawer}
          onDeclinement={onDeclinement}
        />
      </LoadingComp>
    </div>
  );
};

export default Booking;
