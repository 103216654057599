import { TagTypes } from "../TagTypes";

export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      getNotifications: build.query({
        keepUnusedDataFor: 0,
        query: (params) => ({
          url: "notifications",
          params,
        }),
      }),

      readAllNotifications: build.mutation({
        invalidatesTags: [TagTypes.ME],
        query: () => ({
          url: "notifications/mark-all-read",
          method: "post",
        }),
      }),
    }),
  });
}
