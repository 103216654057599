import { Button, Drawer, Input, Select } from "antd";
import { useState } from "react";
import { showWarningMsg } from "../../../../../helpers/windowMassages";
import "./DeclinementDrawer.scss";

const { Option } = Select;
const { TextArea } = Input;

export const DeclinementDrawer = ({ onChange, visible, onDeclinement }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [reason, setReason] = useState("");

  const onSelect = (value) => {
    setSelectedOption(value);
  };

  const onClick = () => {
    if (selectedOption === "other") {
      if (reason) {
        onDeclinement(reason);
        setSelectedOption("");
        setReason("");
      } else {
        showWarningMsg({ content: "Please select a reason" });
      }
    } else {
      onDeclinement(selectedOption);
      setSelectedOption("");
      setReason("");
    }
  };
  return (
    <Drawer
      className="declinement-drawer-wrapper"
      title="Withheld payment"
      placement="right"
      onClose={onChange}
      visible={visible}
    >
      <p>Reason for withhold payment</p>
      <Select
        placeholder="Select a reason"
        onChange={onSelect}
        value={selectedOption}
      >
        <Option value="refunded">Refunded</Option>
        <Option value="reported_issue">Reported Issue</Option>
        <Option value="other">Other</Option>
      </Select>
      {selectedOption === "other" && (
        <div className="decline-reason">
          <label>Please provide some detaIL</label>
          <TextArea
            rows={4}
            placeholder="Please provide a brief description"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
      )}

      <Button disabled={!selectedOption} onClick={onClick}>
        Withhold payment
      </Button>
    </Drawer>
  );
};

export default DeclinementDrawer;
